import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { FetchRecentActivityResponse } from 'types';

const useGetUserActivity = (userId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchRecentActivityResponse>(
    [QueryEntity.RecentUserActivity],
    () => {
      const url = new URL(`${APIBaseChronos}/client/user/activity/recent/${userId}`);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: 'GET',
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      enabled: !!userId,
    },
  );
};

export default useGetUserActivity;
