import { useEffect, useMemo, useState } from 'react';

import { faCircleNotch, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUploadInput from 'components/molecules/FileUploadInput';
import { niceBytes } from 'helpers/bytesCalculator';
import { getFileNameWithExtension } from 'helpers/filenames';
import isPlural from 'helpers/isPlural';
import useFetchCaseDocs from 'hooks/useFetchCaseDocs';
import useUploadFiles from 'hooks/useUploadFiles';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import { ChronosDoc } from 'types';

import { MB_150_IN_BYTES, GB_2_IN_BYTES } from './constants';

interface ExtendedChronosDoc extends ChronosDoc {
  isDuplicate?: boolean;
  isUnsupported?: boolean;
  alreadyExistsInCase: boolean;
}

const FileUploaderComponent = ({
  setUploadDisabled,
  setTotalActiveDocs,
  caseId,
  isCaseCreator,
}: {
  setUploadDisabled: (disabled: boolean) => void;
  setTotalActiveDocs: (docNumber: number) => void;
  caseId: string;
  isCaseCreator?: boolean;
}) => {
  const [docsTab, setDocsTab] = useState<'active' | 'removed'>('active');
  const [allDocs, setAllDocs] = useState<ChronosDoc[]>([]);

  const {
    data: docsResponse,
    refetch: refetchCaseDocs,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading: isLoadingDocuments,
  } = useFetchCaseDocs({ caseId });

  const { onUpload, handleRemoveDoc, isUploading, docRemoving, successfulUploads } = useUploadFiles({
    caseId,
    refetchCaseDocs,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: fetchNextPage,
  });

  useEffect(() => {
    if (docsResponse) {
      const allDocuments = docsResponse.pages.flatMap((page) => page.docs);
      setAllDocs(allDocuments);
    }
  }, [docsResponse]);

  // Doc Filtering
  const docsInCase = useMemo(() => {
    return allDocs.filter((doc: any) => doc.run_id != null) || [];
  }, [allDocs]);

  const docsToRun = useMemo(() => {
    return allDocs.filter((doc: any) => !doc.run_id) || [];
  }, [allDocs]);

  // Splitting docs into active and removed
  const activeDocsAlreadyInCase = useMemo(() => docsInCase.filter((doc: ChronosDoc) => !doc.is_removed), [docsInCase]);
  const activeDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => !doc.is_removed), [docsToRun]);
  const removedDocs = useMemo(() => docsToRun.filter((doc: ChronosDoc) => doc.is_removed), [docsToRun]);

  const markDuplicatesAndUnsupported = (
    docsArray: ChronosDoc[],
    groupedByHash: Record<string, ChronosDoc[]>,
  ): ExtendedChronosDoc[] => {
    return docsArray.map((doc) => ({
      ...doc,
      isDuplicate: groupedByHash[doc.file_hash]?.length > 1,
      alreadyExistsInCase:
        activeDocsAlreadyInCase.filter((docInCase: any) => docInCase.file_hash === doc.file_hash).length > 0,
      isUnsupported: doc.file_extension?.toLowerCase() === 'zip' || doc.file_extension?.toLowerCase() === 'rar',
    }));
  };

  const groupDocsByHash = (docsArray: ChronosDoc[]): Record<string, ChronosDoc[]> => {
    return docsArray.reduce<Record<string, ChronosDoc[]>>((acc, doc) => {
      const key = doc.file_hash || 'undefined'; // Handle undefined file_hash appropriately
      (acc[key] = acc[key] || []).push(doc);
      return acc;
    }, {});
  };

  const sortDocs = (docsArray: ChronosDoc[]): ExtendedChronosDoc[] => {
    const groupedByHash = groupDocsByHash(docsArray);
    const markedDocs = markDuplicatesAndUnsupported(docsArray, groupedByHash);

    return markedDocs.sort((a, b) => {
      if (a.isUnsupported && !b.isUnsupported) return -1;
      if (!a.isUnsupported && b.isUnsupported) return 1;

      const aExistsInCase = a.alreadyExistsInCase;
      const bExistsInCase = b.alreadyExistsInCase;
      if (aExistsInCase && !bExistsInCase) return -1;
      if (!aExistsInCase && bExistsInCase) return 1;

      // Prioritize duplicates within the group that doesn't already exist in the case
      const aIsDuplicate = a.isDuplicate;
      const bIsDuplicate = b.isDuplicate;
      if (aIsDuplicate && !bIsDuplicate) return -1;
      if (!aIsDuplicate && bIsDuplicate) return 1;

      // Sort by file hash as a final tiebreaker
      if (!a.file_hash && b.file_hash) return 1;
      if (a.file_hash && !b.file_hash) return -1;
      return a.file_hash ? a.file_hash.localeCompare(b.file_hash || '') : 0;
    });
  };
  // --------------------------------------------------------

  const showActive = () => {
    setDocsTab('active');
  };

  const showRemoved = () => {
    setDocsTab('removed');
  };

  // Sorted and grouped active documents
  // eslint-disable-next-line
  const sortedActiveDocs = useMemo(() => sortDocs(activeDocs), [activeDocs]);
  const groupedActiveDocs = useMemo(() => groupDocsByHash(sortedActiveDocs), [sortedActiveDocs]);

  // Sorted and grouped removed documents
  // eslint-disable-next-line
  const sortedRemovedDocs = useMemo(() => sortDocs(removedDocs), [removedDocs]);
  const groupedRemovedDocs = useMemo(() => groupDocsByHash(sortedRemovedDocs), [sortedRemovedDocs]);

  const duplicatesActive = Object.values(groupedActiveDocs).filter((group: any) => group.length > 1);
  const duplicatesRemoved = Object.values(groupedRemovedDocs).filter((group: any) => group.length > 1);

  const sum = activeDocs?.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;
  const prohibited_docs_length = activeDocs?.filter((doc: any) => {
    return doc?.file_size > MB_150_IN_BYTES;
  }).length;
  const only_1_prohibited = prohibited_docs_length === 1;

  const size_limit_exceeded = sum > GB_2_IN_BYTES;
  const count_limit_exceeded = (activeDocs?.length || 0) > 1000;

  const unsupported_docs = sortedActiveDocs.filter((doc: ExtendedChronosDoc) => doc.isUnsupported);
  const unsupported_docs_length = unsupported_docs.length;

  const message = prohibited_docs_length
    ? `${prohibited_docs_length} file${only_1_prohibited ? '' : 's'} exceed${
        only_1_prohibited ? 's' : ''
      } 150MB file size limit`
    : size_limit_exceeded && count_limit_exceeded
      ? 'Exceeded 2GB file size and 1000 file count limits.'
      : size_limit_exceeded
        ? 'Exceeded 2GB file size limit.'
        : count_limit_exceeded
          ? 'Exceeded 1000 file count limit.'
          : unsupported_docs_length
            ? `${unsupported_docs_length} unsupported ${isPlural({
                one: 'file',
                more: 'files',
                number: unsupported_docs_length,
              })} (zip/rar).`
            : '';

  useEffect(() => {
    setUploadDisabled(
      !!duplicatesActive.length ||
        prohibited_docs_length > 0 ||
        isUploading ||
        !allDocs.length ||
        size_limit_exceeded ||
        count_limit_exceeded ||
        unsupported_docs.length > 0 ||
        activeDocs.length === 0,
    );
    setTotalActiveDocs(docsResponse?.pages[0]?.pagination?.totalCount || 0);
  }, [
    duplicatesActive,
    prohibited_docs_length,
    docsResponse,
    isUploading,
    size_limit_exceeded,
    count_limit_exceeded,
    unsupported_docs,
    activeDocs,
    setTotalActiveDocs,
    setUploadDisabled,
    allDocs.length,
  ]);

  return (
    <div>
      {!!message && <div className="font-semibold italic text-red-700">{message}</div>}
      <FileUploadInput
        isUploading={isUploading}
        onDrop={onUpload}
        successfulUploads={successfulUploads}
        tooltipText="Supported file types: EML, MHT, DocX, Doc, PDF, and Spreadsheet files (more coming soon). Max file size is 150MB."
      />
      <div className="flex-column flex w-full">
        {isLoadingDocuments && (
          <div className={`flex h-32 w-full items-center justify-center ${isCaseCreator ? 'h-[362px]' : 'h-32'}`}>
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        )}
        {allDocs.length > 0 && (
          <div className="mt-2 w-full">
            <div className="flex flex-row justify-between">
              <div className="mb-2 flex flex-row">
                <div
                  className={`px-6 py-1 ${
                    docsTab === 'active' ? 'border-b-2 border-brandSecondary' : 'text-gray-600'
                  } cursor-pointer`}
                  onClick={showActive}
                >
                  Active
                </div>
                <div
                  className={`px-6 py-1 ${
                    docsTab === 'removed' ? 'border-b-2 border-brandSecondary' : 'text-gray-600'
                  } cursor-pointer`}
                  onClick={showRemoved}
                >
                  Removed
                </div>
              </div>
            </div>
            <div
              className={`flex flex-col justify-between overflow-y-scroll rounded border bg-gray-50 p-2 ${
                isCaseCreator ? 'h-80' : 'h-28'
              }`}
            >
              <div className="flex flex-wrap justify-between gap-2">
                {(docsTab === 'active' ? sortedActiveDocs : sortedRemovedDocs)?.map((doc, idx) => {
                  const file_size_exceeded = doc.file_size > MB_150_IN_BYTES;
                  const isDuplicate = (docsTab === 'active' ? duplicatesActive : duplicatesRemoved).some(
                    (group: any) => {
                      if (!doc.file_hash) return false;
                      return group.some((dupDoc: any) => dupDoc.file_hash === doc.file_hash);
                    },
                  );
                  const isUnsupported = doc.isUnsupported;
                  return (
                    <div
                      className={`flex h-8 w-72 items-center rounded border bg-white p-2 text-xs ${
                        isUnsupported || file_size_exceeded
                          ? 'border-red-500 text-red-700'
                          : isDuplicate
                            ? 'border-orange-500 text-orange-700'
                            : 'border-blue-500 text-blue-700'
                      }`}
                      key={doc.doc_id}
                    >
                      <div className="flex w-full items-center justify-between">
                        {/* {File name} */}
                        <div className="w-1/3 shrink-0 truncate">{getFileNameWithExtension(doc?.file_name)}</div>
                        {/* Extension */}
                        <div className="flex w-1/3 shrink-0 items-center justify-center">
                          <div
                            className={`flex items-center justify-center rounded p-1 uppercase ${
                              file_size_exceeded || isUnsupported ? 'bg-red-200' : ''
                            } ${isDuplicate ? 'bg-orange-200' : 'bg-blue-50'}`}
                          >
                            {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                          </div>
                        </div>
                        {/* Size */}
                        <div className="flex flex-row items-center justify-center">
                          {file_size_exceeded && !isDuplicate && (
                            <>
                              <FontAwesomeIcon
                                data-tooltip-id={`tooltip-exceeded-file-size-${idx}`}
                                icon={faInfoCircle}
                                data-tooltip-content="File size too large for processing"
                                className="ml-2 mr-3 cursor-pointer text-sm text-red-700"
                                style={{ width: '16px' }}
                              />
                              <Tooltip
                                id={`tooltip-exceeded-file-size-${idx}`}
                                className="z-10"
                                style={{
                                  display: 'flex',
                                  width: '300px',
                                  padding: '16px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  gap: '8px',
                                  borderRadius: '16px',
                                  background: '#FFF',
                                  boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                                  color: 'var(--colors-primary-slate-600, #455468)',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                  letterSpacing: '-0.3px',
                                }}
                              />
                            </>
                          )}
                          {isDuplicate && 'Duplicate'}
                          {isUnsupported && 'Unsupported'}
                          {!isDuplicate && !isUnsupported && (doc.file_size ? `${niceBytes(doc.file_size)}` : '---')}
                        </div>

                        {docsTab === 'active' ? (
                          <div
                            className={`ml-auto flex w-8 shrink-0 cursor-pointer items-center justify-center rounded-lg px-2 py-1 font-bold not-italic`}
                            onClick={() => handleRemoveDoc(doc.doc_id)}
                          >
                            {docRemoving === doc.doc_id ? (
                              <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
                            ) : (
                              <FontAwesomeIcon icon={faTrash} />
                            )}
                          </div>
                        ) : (
                          <div className="w-24"></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {hasNextPage && !isFetchingNextPage && (
                <div ref={sentryRef} className="my-2 flex items-center justify-center">
                  <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-brandSecondary" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FileUploaderComponent;
