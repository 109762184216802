import React, { useEffect, useRef, useState } from 'react';

import { faArrowLeft, faArrowRight, faFile, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetFetchConfig from 'api/useGetFetchConfig';
import LabelledTextInput from 'components/molecules/LabelledTextInput';
import FileUploaderComponent from 'components/organisms/DocumentAdder';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { reactSelectStylesCaseCreator } from 'constants/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { MyOptionType } from 'types';

import StatusIndictor from './components/StatusIndicator';
import { useGetCase } from './hooks/useGetCase';
import { useProcessCase } from './hooks/useProcessCase';
import { useSaveCase } from './hooks/useSaveCase';

const CaseCreator: React.FC = () => {
  // State
  const [caseName, setCaseName] = useState<string>('');
  const [caseType, setCaseType] = useState<string>('');
  const [caseIssues, setCaseIssues] = useState<string>('');
  const [caseParties, setCaseParties] = useState<string>('');
  const [caseTimePeriod, setCaseTimePeriod] = useState<string>('');
  const [caseContext, setCaseContext] = useState<string>('');
  const [disputeStatus, setDisputeStatus] = useState<MyOptionType | null>();

  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [totalActiveDocs, setTotalActiveDocs] = useState(0);

  const { caseId } = useParams();
  const navigate = useNavigate();

  const { fetchConfigGET } = useGetFetchConfig();

  // Define refs for each textarea
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTypeRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaIssuesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaPartiesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTimePeriodRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaContextRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '56px';
      if (ref.current.scrollHeight > 56) {
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }
  };

  // Queries
  // Get case
  const { data: responseCase, isLoading: isLoadingCase } = useGetCase(caseId, fetchConfigGET);
  // Update case
  const { mutate: processCase } = useProcessCase();
  // Save case
  const { mutate: saveCase } = useSaveCase(caseId || '');

  // Effects
  // Load in any existing case metadata
  useEffect(() => {
    if (responseCase?.caseObject) {
      setCaseName(responseCase?.caseObject?.case_name);
      setCaseIssues(responseCase?.caseObject?.case_legal_issues);
      setCaseParties(responseCase?.caseObject?.case_parties);
      setCaseTimePeriod(responseCase?.caseObject?.case_key_time_period);
      setCaseContext(responseCase?.caseObject?.case_key_context);
      setDisputeStatus(
        DISPUTE_STATUS_OPTIONS[responseCase?.caseObject?.dispute_status as keyof typeof DISPUTE_STATUS_OPTIONS],
      );
      setCaseType(responseCase?.caseObject?.case_type);
    }
  }, [responseCase]);

  // Handlers
  const handleChangeCaseName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseName(e.target.value);
    autoResize(textAreaNameRef);
  };

  const handleChangeCaseType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseType(e.target.value);
    autoResize(textAreaTypeRef);
  };
  const handleChangeCaseIssues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseIssues(e.target.value);
    autoResize(textAreaIssuesRef);
  };

  const handleChangeCaseContext = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseContext(e.target.value);
    autoResize(textAreaContextRef);
  };

  const handleChangeCaseTimePeriod = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseTimePeriod(e.target.value);
    autoResize(textAreaTimePeriodRef);
  };

  const handleChangeCaseParties = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseParties(e.target.value);
    autoResize(textAreaPartiesRef);
  };

  const handleChangeDisputeStatus = (val: MyOptionType) => {
    setDisputeStatus(val);
  };

  const handleUpdateCase = () => {
    if (!caseId) return;
    processCase(
      {
        caseId: caseId,
        caseName,
        caseType,
        caseIssues,
        caseParties,
        disputeStatus: disputeStatus?.value,
      },
      {
        onSuccess: () => {
          navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`);
        },
      },
    );
  };

  const handleClickBack = () => {
    const toastId = toast.loading('Saving case...');
    saveCase(
      {
        caseName,
        caseType,
        caseIssues,
        caseParties,
        disputeStatus: disputeStatus?.value,
      },
      {
        onSuccess: () => {
          toast.update(toastId, {
            render: 'Case saved successfully',
            type: 'success',
            isLoading: false,
            autoClose: 1500,
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
        },
        onError: () => {
          toast.update(toastId, {
            render: 'Failed to save case',
            type: 'error',
            isLoading: false,
            autoClose: 1500,
          });
          navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
        },
      },
    );
  };

  return (
    <div className="relative flex w-full flex-row gap-6 overflow-auto">
      <div className="flex w-full flex-col items-start justify-start pl-8">
        {isLoadingCase ? (
          <div className="flex h-full w-full flex-col items-center justify-start pt-14">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            <div>
              <div className="mt-5 flex items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mr-4 h-5 w-5 cursor-pointer"
                  onClick={() => handleClickBack()}
                />
                <div className="w-full text-xl font-bold not-italic">Create New Case</div>
              </div>
              <div className="absolute right-6 top-4">
                <StatusIndictor />
              </div>
            </div>

            <div className="flex w-full pb-4 text-gray-800">
              <div className="mt-4 w-1/2">
                <div className="mb-4 w-fit border-b pr-20 text-lg">
                  <FontAwesomeIcon icon={faPenToSquare} className="mr-2 h-4 w-4 text-gray-600" />
                  Case Details
                </div>

                <div className="mt-4 flex flex-col gap-4">
                  <LabelledTextInput
                    id="caseNameInput"
                    value={caseName}
                    onChange={handleChangeCaseName}
                    label="Name of case"
                  />
                  <div>
                    <LabelledTextInput
                      id="caseIssuesInput"
                      value={caseIssues}
                      onChange={handleChangeCaseIssues}
                      label="Case issues"
                    />
                    <div className="mt-1 px-1 text-xs text-gray-500">
                      Outline the key issues in the case as you understand them, either in list format or prose.
                    </div>
                  </div>

                  <div className="mt-2 border-b border-gray-300"></div>

                  <div>
                    <LabelledTextInput
                      id="caseTypeInput"
                      value={caseType}
                      onChange={handleChangeCaseType}
                      label="Type of case"
                      small
                    />
                    <div className="mt-1 px-1 text-xs text-gray-500">
                      Case type could be Arbitration, Employment Dispute, Public Inquiry, Commercial Litigation, etc...
                    </div>
                  </div>

                  <div>
                    <LabelledTextInput
                      id="casePartiesInput"
                      value={caseParties}
                      onChange={handleChangeCaseParties}
                      label="Key actors (persons or entities)"
                      small
                    />
                    <div className="mt-1 px-1 text-xs text-gray-500">
                      Identifying the key actors helps Wexler's algorithms to focus on which entities are most important
                      in the case.
                    </div>
                  </div>

                  <div className="mb-2 mt-2 border-b border-gray-300"></div>

                  <div>
                    <LabelledTextInput
                      id="caseContextInput"
                      value={caseContext}
                      onChange={handleChangeCaseContext}
                      label="Additional context (optional)"
                      small
                    />
                    <div className="mt-1 px-1 text-xs text-gray-500">
                      Any other contextual information Wexler should be aware of. E.g. "There is a parallel litigation
                      happening in a different country with similar issues" or "The claimant is a public figure".
                    </div>
                  </div>
                  <div>
                    <LabelledTextInput
                      id="caseTimePeriodInput"
                      value={caseTimePeriod}
                      onChange={handleChangeCaseTimePeriod}
                      label="Case time period (optional)"
                      small
                    />
                    <div className="mt-1 px-1 text-xs text-gray-500">
                      The estimated time period of the documents, e.g. "1960 to 1990" or "Early 2000s" (don't worry
                      about the formatting of the dates).
                    </div>
                  </div>

                  <div className="relative flex items-center text-sm">
                    <Select
                      options={Object.values(DISPUTE_STATUS_OPTIONS)}
                      className={`w-full rounded-md outline-none`}
                      styles={reactSelectStylesCaseCreator}
                      onChange={handleChangeDisputeStatus}
                      value={disputeStatus}
                      placeholder={'Dispute status (optional)'}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4 w-1/2 px-6">
                <div className="flex text-lg">
                  <div className="w-fit border-b pr-20 text-lg">
                    <FontAwesomeIcon icon={faFile} className="mr-2 h-4 w-4 text-gray-600" />
                    Files {totalActiveDocs ? `(${totalActiveDocs})` : ''}{' '}
                  </div>
                </div>
                <FileUploaderComponent
                  setUploadDisabled={setUploadDisabled}
                  setTotalActiveDocs={setTotalActiveDocs}
                  caseId={caseId || ''}
                  isCaseCreator={true}
                />

                <button
                  className="mt-5 flex shrink-0 cursor-pointer flex-row items-center justify-center rounded-lg bg-brandSecondary px-6 py-2 text-center text-base font-bold not-italic leading-6 text-white hover:bg-brandSecondary-hover disabled:cursor-not-allowed disabled:opacity-20"
                  onClick={handleUpdateCase}
                  disabled={uploadDisabled || !caseName || !caseType || !caseIssues || !caseParties}
                >
                  Start Processing
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CaseCreator;
