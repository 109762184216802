import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface ProcessCaseParams {
  caseId: string;
  caseName: string;
  caseType: string;
  caseIssues: string;
  caseParties: string;
  disputeStatus?: string;
  caseKeyTimePeriod?: string;
  caseKeyContext?: string;
}

export const useProcessCase = () => {
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation(
    async ({
      caseId,
      caseName,
      caseType,
      caseIssues,
      caseParties,
      disputeStatus,
      caseKeyTimePeriod,
      caseKeyContext,
    }: ProcessCaseParams) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus,
          caseKeyTimePeriod,
          caseKeyContext,
        },
      });

      const response = await fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfig);
      return response.json();
    },
  );
};
