import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { FetchOrgUsageDataRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useFetchOrgUsageData = (
  enabled: boolean,
  startDate?: string,
  endDate?: string,
  matterIds?: string[],
  userIds?: string[],
  allUsers?: { id: string; email: string }[],
  orgIds?: string[],
) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchOrgUsageDataRes>(
    [QueryEntity.OrgUsageData, enabled, startDate, endDate, matterIds, userIds, allUsers, orgIds],
    () => {
      const url = new URL(`${APIBaseChronos}/client/org/usageData`);
      if (startDate) url.searchParams.append('startDate', startDate);
      if (endDate) url.searchParams.append('endDate', endDate);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: 'POST',
        body: JSON.stringify({
          matterIds: matterIds?.length ? matterIds : null,
          userIds,
          allUsers: allUsers ? allUsers : [],
          orgIds,
        }),
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled,
    },
  );
};

export default useFetchOrgUsageData;
