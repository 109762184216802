import { useCallback, useEffect, useState } from 'react';

import { faDownload, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView } from 'analytics/Mixpanel';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import Modal from 'components/molecules/Modals/Settings';
import { useUserContext } from 'Contexts/User';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { ChronosChronologyEvent, ChronosDoc } from 'types';

import ChronologyTableToolbar from '../ChronologyTableToolbar';
import ChronologyTimeline from '../ChronologyTimeline';
import ChronologyDownloadModal from '../components/ChronologyDownloadModal';
import ChronologyEventAdder from '../components/ChronologyEventAdder';
import useChronologyDownload from '../hooks/useChronologyDownload';

const sortByEntryOrder = (a: ChronosChronologyEvent, b: ChronosChronologyEvent) => {
  return a.entry_order - b.entry_order;
};

const ChronologyEditor = () => {
  // State
  const [chronology, setChronology] = useState<ChronosChronologyEvent[]>([]);
  const [totalNumberOfFacts, setTotalNumberOfFacts] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const [globalFilter, setGlobalFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [lineIdToDelete, setLineIdToDelete] = useState('');
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);

  const page = searchParams.get('page');
  const chronologyId = searchParams.get('chronologyId') || '';
  const chronologyTitle = searchParams.get('title') || '';
  const caseId = searchParams.get('caseId');
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: page ? Number(page) - 1 : 0,
    pageSize: 100,
  });

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const { user } = useUserContext();

  // ----- Data fetching -----
  // Fetch chronology
  const {
    data: responseChronology,
    isFetching: isLoadingChronologyTable,
    refetch: refetchCaseChronology,
  } = useQuery(
    ['userChronology', chronologyId],
    () => {
      if (chronologyId) {
        return fetch(
          `${APIBaseChronos}/client/case/chronology/${chronologyId}?page=${pageIndex + 1}&pageSize=${pageSize}`,
          fetchConfigGET,
        ).then((res) => {
          return res.json() as Promise<{ chronology: ChronosChronologyEvent[]; totalNumberOfFacts: number }>;
        });
      }
    },
    {
      enabled: false,
    },
  );

  // Fetch docs
  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(
    ['userDocs', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
    },
  );

  const { fetchDownload, isLoadingDownload } = useChronologyDownload({
    chronologyId,
    caseId,
  });

  // Effects
  useEffect(() => {
    refetchCaseChronology();
    refetchCaseDocs();
    // eslint-disable-next-line
  }, [chronologyId, pageIndex]);

  useEffect(() => {
    trackPageView('Chronology Editor');
  }, []);

  useEffect(() => {
    if (responseChronology?.chronology && responseChronology?.chronology?.length > 0) {
      const orderedChronology = responseChronology?.chronology.sort(sortByEntryOrder);
      setChronology(orderedChronology);
      setTotalNumberOfFacts(responseChronology?.totalNumberOfFacts);
    }
  }, [responseChronology]);

  useEffect(() => {
    if (responseDocs?.docs && responseDocs?.docs?.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  // Handlers
  const handleBack = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('chronologyId');
    searchParams.delete('title');
    searchParams.delete('page');
    navigate('/app/chronos/case-editor/data/chronology?' + searchParams.toString(), { replace: true });
  }, [location.search, navigate]);

  const openModal = useCallback(() => setModalIsOpen(true), []);
  const closeModal = useCallback(() => setModalIsOpen(false), []);

  const handleNewFactCreated = () => {
    trackCustomEvent('New Fact Created From Chronology Editor');
    setChronology([]);
    refetchCaseChronology();
    closeModal();
  };

  const handleOpenDeleteModal = (lineId: string) => {
    setDeleteModalIsOpen(true);
    setLineIdToDelete(lineId);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModalIsOpen(false);
    setLineIdToDelete('');
  };

  const handleRemoveRow = async () => {
    const toastId = toast.loading('Removing fact...');
    const endpointToUpdate = `${APIBaseChronos}/client/case/chronology/${lineIdToDelete}`;
    const fetchConfig = getFetchConfig({ method: 'PUT', data: { is_hidden: true } });

    trackCustomEvent('Remove Fact From Chronology');

    try {
      await fetch(endpointToUpdate, fetchConfig);
      refetchCaseChronology();
      toast.update(toastId, {
        render: 'Fact removed successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (err) {
      console.error('Fetch Error: ', err);
      toast.update(toastId, {
        render: 'Failed to remove fact. Please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      handleCloseDeleteModal();
      setLineIdToDelete('');
    }
  };

  const handleDownload = useCallback(
    ({ title, columns, format }: { title: string; columns: string[]; format: string }) => {
      trackCustomEvent(`Download Chronology ${format}`);
      fetchDownload(
        { title, columns, format },
        {
          onSuccess: async () => {
            // Close the modal after successful download
            await new Promise((resolve) => setTimeout(resolve, 200));
            setDownloadModalIsOpen(false);
          },
        },
      );
    },
    [fetchDownload],
  );

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pageIndex - 1);
  const nextPage = () => goToPage(pageIndex + 1);
  const totalPages = Math.ceil((totalNumberOfFacts || 0) / pageSize);

  const onSearchCall = () => {
    //setAppliedGlobalFilter(globalFilter);
  };

  return (
    <>
      {chronologyId && (
        <div className="flex h-full flex-col">
          <ChronologyTableToolbar
            globalFilter={globalFilter}
            chronologyTitle={chronologyTitle}
            setGlobalFilter={setGlobalFilter}
            handleBack={handleBack}
            openModal={openModal}
            openDownloadModal={() => setDownloadModalIsOpen(true)}
            canGetPrevPage={pageIndex > 0}
            canGetNextPage={pageIndex < totalPages}
            prevPage={prevPage}
            nextPage={nextPage}
            currentPage={pageIndex}
            noOfPages={totalPages}
            goToPage={goToPage}
            onSearchCall={onSearchCall}
          />
          <div className="mb-4 flex-grow overflow-hidden pt-4">
            {isLoadingChronologyTable && (
              <div className="flex h-24 w-full items-center justify-center">
                <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
              </div>
            )}
            {!isLoadingChronologyTable && chronology && chronology.length > 0 && (
              <div className="h-full">
                <ChronologyTimeline
                  chronology={chronology}
                  currentUserId={user?.userId || ''}
                  handleOpenDeleteModal={handleOpenDeleteModal}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <Modal
        title={
          <div className="flex items-center gap-2 font-semibold text-red-700">
            <FontAwesomeIcon icon={faTrash} />
            Delete Fact
          </div>
        }
        isOpen={deleteModalIsOpen}
        handleClose={handleCloseDeleteModal}
        content={
          <div className="flex h-48 flex-col gap-2 px-6 pt-4">
            <p>Are you sure you want to remove this fact from this chronology?</p>
            <div className="w-full border-b"></div>
            {/* <p className="text-sm text-gray-500">You can add a fact back later from the Facts table.</p> */}
            <div className="mt-6 flex w-full items-center justify-between gap-2">
              <Button text="Delete" rounded="md" type="delete" onClick={handleRemoveRow} />
              <Button text="Cancel" rounded="md" type="secondary" onClick={handleCloseDeleteModal} />
            </div>
          </div>
        }
        size="small"
      />
      <Modal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faPlusCircle} className="" />
            Add Fact
          </div>
        }
        isOpen={modalIsOpen}
        handleClose={closeModal}
        content={
          <ChronologyEventAdder
            docs={docs}
            handleNewFactCreated={handleNewFactCreated}
            chronologyId={chronologyId || ''}
          />
        }
        size="small"
      />
      <Modal
        title={
          <div className="flex items-center gap-2 font-semibold text-blue-500">
            <FontAwesomeIcon icon={faDownload} className="" />
            Download Chronology
          </div>
        }
        isOpen={downloadModalIsOpen}
        handleClose={() => setDownloadModalIsOpen(false)}
        content={
          <ChronologyDownloadModal
            handleDownload={handleDownload}
            chronologyTitle={chronologyTitle}
            loadingDownload={isLoadingDownload}
          />
        }
        size="small"
      />
    </>
  );
};

export default ChronologyEditor;
