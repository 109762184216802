import ActivityPanel from './components/ActivityPanel';
import MattersPanel from './components/MattersPanel';

const MattersExplorer = () => {
  return (
    <div className="flex h-screen w-full flex-col">
      <div className="min-h-0 w-full overflow-y-auto px-6 py-4">
        <div className="flex w-full flex-col gap-2 text-gray-800">
          <div className="w-full">
            <h1 className="mt-1 w-full text-2xl font-semibold not-italic text-gray-900">Welcome</h1>
          </div>

          <div className="relative mb-2 flex h-full min-h-[calc(100vh-6rem)] w-full rounded-lg border-2 border-gray-200 border-opacity-40 bg-white">
            <ActivityPanel />
            <MattersPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MattersExplorer;
