import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface CreateCaseResponse {
  newCaseObject: {
    case_id: string;
  };
}

export const useCreateCase = () => {
  const navigate = useNavigate();
  const { getFetchConfig } = useGetFetchConfig();

  return useMutation<CreateCaseResponse, Error, string | null>(
    async (matterId) => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          matterId: matterId,
        },
      });
      const res = await fetch(`${APIBaseChronos}/client/case`, fetchConfig);
      if (!res.ok) {
        throw new Error('Failed to create case');
      }
      return res.json();
    },
    {
      onSuccess: (data) => {
        navigate(`/app/chronos/case-creator/${data.newCaseObject.case_id}`);
      },
    },
  );
};
