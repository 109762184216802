import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { FetchCompletedGuidesResponse } from 'types';

const useGetCompletedGuides = (userId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchCompletedGuidesResponse>(
    'fetchCompletedGuides',
    () => {
      const url = new URL(`${APIBaseChronos}/client/user/guides/${userId}`);

      return fetch(url.toString(), {
        ...fetchConfigGET,
        method: 'GET',
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error(err);
        });
    },
    {
      enabled: !!userId,
    },
  );
};

export default useGetCompletedGuides;
