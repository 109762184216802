import { APIBaseChronos } from 'api/hosts';
import { FetchCaseDocsRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useInfiniteQuery } from 'react-query';

interface FetchCaseDocs {
  caseId: string;
}

const useFetchCaseDocs = ({ caseId }: FetchCaseDocs) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useInfiniteQuery<FetchCaseDocsRes>({
    refetchOnWindowFocus: false,
    queryKey: ['caseDocs', caseId],
    queryFn: async ({ pageParam }) => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}?page=${pageParam}`, fetchConfigGET).then((res) =>
        res.json(),
      );
    },
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.totalPages > lastPage.pagination.currentPage
        ? lastPage.pagination.currentPage + 1
        : null;
    },
  });
};

export default useFetchCaseDocs;
