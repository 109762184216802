import { useMemo } from 'react';
import React from 'react';

import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ChronosRun } from 'types';

import { HeaderContent } from './HeaderContent';
import useGetDocumentColumns from './useGetDocumentColumns';
interface RunsViewProps {
  runs: ChronosRun[];
}

const RunsView = React.memo(({ runs }: RunsViewProps) => {
  const columns = useGetDocumentColumns();

  const data = useMemo(
    () =>
      runs.map((run, idx) => ({
        ...run,
        item_number: idx,
      })),
    [runs],
  );

  const table = useReactTable<ChronosRun>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const rowsToRender = table.getRowModel().rows;

  return (
    <div className="flex-column flex max-h-[calc(100vh-250px)] w-full rounded-lg border-2 shadow-xl">
      {runs && runs.length > 0 && (
        <div className="w-full">
          <table className="my-0 w-full">
            <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <th
                        key={header.id}
                        className="font-medium not-italic"
                        style={{ width: header.column.getSize(), color: 'var(--colors-primary-slate-400, #8897AE)' }}
                      >
                        <HeaderContent header={header} />
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {rowsToRender.map((row, i) => {
                return (
                  <tr
                    style={{
                      background: i % 2 === 0 ? 'white' : '#F9FAFB',
                      borderBottom: runs.length === i + 1 ? '' : '1px solid #c8cad7',
                    }}
                    key={row.id}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td className="pl-3" key={cell.id} id={cell.id} style={{ width: cell.column.getSize() }}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
});

export default RunsView;
