import { useCallback, useEffect, useRef, useState } from 'react';

import { trackPageView } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import PDFViewer from 'components/organisms/PDFViewer';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChronosDoc, ChronosDocumentCoordinate, Coordinate } from 'types';

const DocViewer = ({
  caseId,
  docId,
  eventId,
  pageNumber,
}: {
  caseId: string;
  docId: string;
  eventId: string;
  pageNumber: number;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loadingFile, setLoadingFile] = useState(true);
  const [isDocumentView, setIsDocumentView] = useState(true);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState([]);

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/client/case/doc/download/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET]); // Add all dependencies here

  const { refetch: fetchDocument } = useQuery(['downloadDoc', docId], getDocumentUrl, { enabled: false });

  const getResponseDocs = () => {
    if (caseId) {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseDocs } = useQuery({ queryKey: ['userDocs'], queryFn: getResponseDocs });

  const getRefCoords = () => {
    if (eventId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinates/${eventId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    } else if (docId) {
      return fetch(`${APIBaseChronos}/client/case/fact/coordinatesByDocId/${docId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    }
  };

  const { data: responseCoordinates } = useQuery({
    queryKey: ['referenceCoordinates', docId, eventId],
    queryFn: getRefCoords,
  });

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    setIsDocumentView(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('eventId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  // Effects
  useEffect(() => {
    if (docId) {
      setLoadingFile(true);
      fetchDocument().then(({ data: documentUrl }) => {
        if (documentUrl && typeof documentUrl === 'string') {
          setFileUrl(documentUrl);
        }
        setLoadingFile(false);
      });
    }
    // eslint-disable-next-line
  }, [docId]);

  useEffect(() => {
    if (docId && responseCoordinates) {
      setMinPage(responseCoordinates.minPage);
      // setDocuments(docIdsRelatedToEvent);
      setHighlightCoordinates(
        responseCoordinates?.factsCoordinates
          ?.filter((coordinate: ChronosDocumentCoordinate) => coordinate.doc_id === docId)
          ?.map((coordinate: ChronosDocumentCoordinate) => coordinate.coordinate_details)
          ?.flat()
          ?.map((coordinate: Coordinate) => {
            return {
              factId: coordinate.fact_id,
              height: coordinate.height_c * 100,
              left: coordinate.left_c * 100,
              pageIndex: coordinate.n_page - 1,
              top: coordinate.top_c * 100,
              width: coordinate.width_c * 100,
            };
          }) || [],
      );
    }
  }, [responseCoordinates, docId, eventId]);

  useEffect(() => {
    if (eventId) {
      setIsDocumentView(true);
    }
  }, [eventId]);

  const hasContentFilter = responseDocs?.docs?.length
    ? responseDocs.docs.find((doc: ChronosDoc) => doc.doc_id === docId)?.content_filter
    : false;

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-hidden">
        {fileUrl === 'download-error' ? (
          'File cannot be displayed, please try again later.'
        ) : (
          <div ref={viewerRef} className="h-full">
            <PDFViewer
              fileUrl={fileUrl}
              loading={loadingFile}
              initialPage={pageNumber || minPage}
              highlights={highlightCoordinates}
              handleClosePDFViewer={handleCloseDocDetailView}
              closeButtonText="Close"
              defaultZoom={0.8}
              type="inline"
            />
          </div>
        )}
      </div>

      {!eventId && hasContentFilter && !isDocumentView && (
        <div className="flex h-24 w-full items-center justify-center">
          <p>
            Document did not pass content filter, possible illicit content forbidden by model provider. Please manually
            add facts.
          </p>
        </div>
      )}
    </div>
  );
};

export default DocViewer;
