import { useEffect, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaginationState } from '@tanstack/react-table';
import { trackPageView, trackCustomEvent } from 'analytics/Mixpanel';
import { CHRONOLOGY_STEPS } from 'constants/tours';
import { useUserContext } from 'Contexts/User';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tour from 'reactour';
import { ChronosDoc, ChronosFact } from 'types';
import { Filters } from 'types';

import FactsEditorTable from './FactsEditorTable';
import FactsEditorToolbar from './FactsEditorToolbar';
import useFactsData from './hooks/useFactsData';
import useKeyFacts from './hooks/useKeyFacts';
import useToggleAll from './hooks/useToggleAllIncluded';
import DocViewer from '../../components/DocViewer/FactDocViewer';
import useFetchDocIds from '../DocumentsEditor/hooks/useFetchDocIds';

const FactsEditor = () => {
  const [globalFilterInput, setGlobalFilterInput] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');

  // Query params
  const [searchParams, setSearchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const pageNumber = searchParams.get('page') || '0';
  const docId = searchParams.get('docId');
  const docPageNumber = parseInt(searchParams.get('docPageNumber') || '0', 10);

  const eventId = searchParams.get('eventId');
  const [isDocViewerOpen, setIsDocViewerOpen] = useState(false);
  useEffect(() => {
    if (docId) {
      setIsDocViewerOpen(true);
    } else {
      setIsDocViewerOpen(false);
    }
  }, [docId]);

  const [keyFactFilterActive, setKeyFactFilterActive] = useState(false);
  const [filters, setFilters] = useState<Filters>({});
  const [facts, setFacts] = useState<ChronosFact[]>([]);
  const [allEventIds, setAllEventIds] = useState<string[]>([]);
  const [isDateDirty, setIsDateDirty] = useState(false);
  const [isLoadingFacts, setIsLoadingFacts] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: parseInt(pageNumber), pageSize: 50 });
  const [totalNumberOfFacts, setTotalNumberOfFacts] = useState(0);
  const [totalNumberOfIncludedFacts, setTotalNumberOfIncludedFacts] = useState(0);
  const [createChronologyTourOpen, setCreateChronologyTourOpen] = useState(false);

  // Custom hooks
  const { mutateAsync: toggleAllIncluded } = useToggleAll();
  const { user } = useUserContext();

  // Data - facts, key facts
  const {
    data: responseFacts,
    isLoading,
    isFetching: isFetchingFacts,
    refetch: refetchCaseFacts,
  } = useFactsData({
    docId: null,
    caseId,
    searchQuery: globalFilter,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
    filters,
  });
  const { data: keyFactsData, isLoading: isLoadingKeyFacts } = useKeyFacts(caseId);

  const [keyFactsSet, setKeyFactsSet] = useState(new Set<string>());

  const { data: responseDocs, refetch: refetchCaseDocs } = useFetchDocIds(caseId);

  const clearSearch = async () => {
    setGlobalFilterInput('');
    setGlobalFilter('');
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetchCaseFacts();
  };

  const handleGlobalFilterChange = (value: string) => {
    setGlobalFilterInput(value);
  };
  // ---------------------------------------------------------------------------

  // useEffects
  useEffect(() => {
    trackPageView('Facts Page');
  }, []);

  useEffect(() => {
    if (!keyFactsData) return;

    setKeyFactsSet(new Set(keyFactsData?.event_ids));

    // Check the search params for the key fact true option and if so, then set key fact filters
    if (searchParams.get('viewKeyFacts') === 'true' && keyFactsData) {
      setKeyFactFilterActive(true);
      setFilters({ event_id: keyFactsData.event_ids.map((id) => ({ label: id, value: id })) });
    }
  }, [keyFactsData, searchParams]);

  useEffect(() => {
    setIsLoadingFacts(isLoading);
  }, [isLoading]);

  useEffect(() => {
    refetchCaseFacts();
    // eslint-disable-next-line
  }, [pagination.pageIndex, filters]);

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (globalFilter === '') {
      setAllEventIds([]);
    }
  }, [globalFilter]);

  const [updatedFactsData, setUpdatedFactsData] = useState<{ [key: string]: ChronosFact }>({});
  const updateFactData = (eventId: string, data: Partial<ChronosFact>) => {
    setUpdatedFactsData((prev) => ({
      ...prev,
      [eventId]: { ...prev[eventId], ...data },
    }));
  };

  // Post process facts after they are fetched
  useEffect(() => {
    if (responseFacts?.facts) {
      setTotalNumberOfFacts(responseFacts.totalFactsCount);
      setTotalNumberOfIncludedFacts(responseFacts.includedFactsCount);

      const modifiedFacts = responseFacts.facts.map((fact: ChronosFact) => ({
        ...fact,
        ...updatedFactsData[fact.event_id], // Merge with any updated data
        source_doc: fact.documents ? fact.documents.map((doc) => doc.doc_id).join(',') : '',
        documents: fact.documents ? fact.documents : [],
      }));
      setFacts(modifiedFacts);

      // If result has come from search then we have a file called eventIds with all the vent ids from the search
      if (responseFacts.eventIds) {
        setAllEventIds(responseFacts.eventIds);
      }
    }
    setIsDateDirty(false);
    setIsLoadingFacts(false);
  }, [responseFacts, updatedFactsData]);

  // Start chronology tour from summary page
  useEffect(() => {
    // Check if explore = true is in seach params
    if (isLoadingFacts || isLoading || isFetchingFacts) return;
    if (searchParams.get('chronologyTour') === 'true') {
      setCreateChronologyTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('chronologyTour');
      // Update the URL without the 'chronologyTour' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, isLoadingFacts, isLoading, isFetchingFacts]);

  // Handlers
  const handleToggleAll = async (toggleValue: boolean): Promise<void> => {
    if (!caseId) {
      throw new Error('No caseId provided');
    }

    const filterDict: { [key: string]: string[] } = {};
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key].length > 0) {
        filterDict[key] = filters[key].map((val) => String(val.value));
      }
    });

    // Add allEventIds to the filterParams if it's set
    if (allEventIds.length > 0) {
      filterDict['event_id'] = allEventIds;
    }

    try {
      await toggleAllIncluded({ caseId, toggleValue, filterParams: filterDict });
      await refetchCaseFacts();
    } catch (err) {
      console.error('Error toggling all facts:', err);
      toast.error('There was an error updating the fields. Please try again later.');
      throw err;
    }
  };

  const onSearchCall = async () => {
    trackCustomEvent('Search in Facts Editor');
    if (!docId) {
      // Reset page to 0
      const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
      setPagination(newPaginationState);

      setGlobalFilter(globalFilterInput); // Set the actual filter used for fetching
      await new Promise((resolve) => setTimeout(resolve, 0));
      refetchCaseFacts(); // Trigger the refetch
    }
  };

  const handleKeyFactFilterToggle = async () => {
    // If keyFactFilterActive is currently true, then remove the event_id filter from the filters
    if (keyFactFilterActive) {
      setKeyFactFilterActive(!keyFactFilterActive);
      const newFilters = { ...filters };
      delete newFilters['event_id'];
      setFilters(newFilters);
      // Remove
    } else {
      trackCustomEvent('Key Facts Filter Toggled');
      // If keyFactFilterActive is currently false, then fetch key fact event_ids and add to filters
      setKeyFactFilterActive(!keyFactFilterActive);
      if (keyFactsData?.event_ids) {
        goToPage(0);
        const keyFactFilters = keyFactsData.event_ids.map((id) => ({ label: id, value: id }));
        const newFilters = { ...filters };
        newFilters['event_id'] = keyFactFilters;
        setFilters(newFilters);
      }
    }
  };

  // Pagination
  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };
  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);
  const totalPages = Math.ceil((totalNumberOfFacts || 0) / pagination.pageSize);

  return (
    <div className="flex h-full min-w-full flex-col">
      <FactsEditorToolbar
        caseId={caseId}
        isLoading={isLoading || isFetchingFacts}
        // Search
        globalFilter={globalFilterInput}
        clearSearch={clearSearch}
        setGlobalFilter={handleGlobalFilterChange}
        onSearchCall={onSearchCall}
        // Results
        refetchCaseFacts={refetchCaseFacts}
        resultsLength={totalNumberOfFacts}
        resultsIncludedLength={totalNumberOfIncludedFacts}
        // Pagination
        canGetPrevPage={pagination.pageIndex > 0}
        canGetNextPage={pagination.pageIndex < totalPages - 1}
        prevPage={prevPage}
        nextPage={nextPage}
        goToPage={goToPage}
        currentPage={pagination.pageIndex}
        noOfPages={totalPages}
        isDateDirty={isDateDirty}
        // Key facts
        loadingKeyFacts={isLoadingKeyFacts}
        hasKeyFacts={Boolean(keyFactsData && keyFactsData.event_ids && keyFactsData.event_ids.length > 0)}
        keyFactFilterActive={keyFactFilterActive}
        handleKeyFactFilterToggle={handleKeyFactFilterToggle}
        // -----------------
        handleToggleAll={handleToggleAll}
        allDocuments={(responseDocs?.docs as ChronosDoc[]) || []}
        activeFilters={filters}
        setFilters={setFilters}
      />

      <div className="flex h-full overflow-hidden pb-2">
        {isLoadingFacts || (isFetchingFacts && facts.length === 0) ? (
          <div className="flex h-full w-full items-center justify-center">
            <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
          </div>
        ) : (
          <div
            className={`h-full overflow-y-auto transition-all duration-300 ${isDocViewerOpen ? 'w-7/12' : 'w-full'}`}
          >
            <FactsEditorTable
              facts={facts}
              keyFactIdSet={keyFactsSet}
              caseId={caseId || ''}
              userId={user?.userId || ''}
              setIsDateDirty={setIsDateDirty}
              setTotalNumberOfIncludedFacts={setTotalNumberOfIncludedFacts}
              // Update fact data
              updateFactData={updateFactData}
            />
          </div>
        )}
        <div
          className={`transition-all duration-300 ease-in-out ${
            isDocViewerOpen ? 'ml-2 w-5/12 opacity-100' : 'w-0 opacity-0'
          } overflow-hidden rounded border-2 bg-white`}
        >
          <div
            className={`h-full w-full transition-transform duration-300 ease-in-out ${
              isDocViewerOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            {docId && (
              <DocViewer docId={docId} caseId={caseId || ''} eventId={eventId || ''} pageNumber={docPageNumber} />
            )}
          </div>
        </div>
      </div>
      <Tour
        startAt={0}
        steps={CHRONOLOGY_STEPS}
        isOpen={createChronologyTourOpen}
        onRequestClose={() => setCreateChronologyTourOpen(false)}
      />
    </div>
  );
};

export default FactsEditor;
