import React, { useState, useEffect, useMemo } from 'react';

import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgMemberInfo, useAuthInfo } from '@propelauth/react';
import Button from 'components/atoms/Button';
import EmptyState from 'components/molecules/EmptyState';
import SearchBox from 'components/molecules/SearchBox';
import Pagination from 'components/organisms/Pagination';
import { useUserContext } from 'Contexts/User';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';

import useMatters from '../../hooks/useMatters';
import { QueryParams } from '../../types';
import { MatterItem } from '../../types';
import MatterItemComponent from './components/MatterItem';

const roles = {
  ADMIN: 'Admin',
};

const MattersPanel = () => {
  // State
  const [searchText, setSearchText] = useState('');
  const [searchTextQuery, setSearchTextQuery] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dateFromQuery = searchParams.get('dateFrom') || '';
  const dateToQuery = searchParams.get('dateTo') || '';
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';
  const pageQuery = parseInt(searchParams.get('page') || '0') || 0;

  const queryParams: QueryParams = useMemo(
    () => ({
      search: searchTextQuery,
      page: pageQuery,
      dateFrom: dateFromQuery,
      dateTo: dateToQuery,
      sortBy: sortByQuery,
    }),
    [searchTextQuery, pageQuery, dateFromQuery, dateToQuery, sortByQuery],
  );

  const { isLoading, responseData, refetch } = useMatters(queryParams);

  // Auth stuff
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs();
  const asignedOrg = orgs?.length && (orgs[0] as OrgMemberInfo);
  const assignedRole = asignedOrg && asignedOrg?.userAssignedRole;
  const isAdmin = assignedRole === roles['ADMIN'];
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.orgSuperAdmin === 'true';

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  useEffect(() => {
    setTotalPages(responseData?.total_pages || 1);
  }, [responseData]);

  const handleClickMatterItem = (id: string) => {
    navigate(`/app/chronos/explore?matterId=${id}`);
  };

  const handleClickEditMatter = (id: string) => {
    navigate(`/app/chronos/matter-editor/${id}`);
  };

  const goToNewMatter = () => {
    navigate(`/app/chronos/matter-creator`);
  };

  const handleGoToNextPage = () => {
    navigate(`/app/chronos/matters?page=${pageQuery + 1}${searchTextQuery ? `&searchText=${searchTextQuery}` : ''}`);
  };

  const handleGoToPreviousPage = () => {
    navigate(`/app/chronos/matters?page=${pageQuery - 1}${searchTextQuery ? `&searchText=${searchTextQuery}` : ''}`);
  };

  const goToPage = (value: number) => {
    navigate(`/app/chronos/matters?page=${value}${searchTextQuery ? `&searchText=${searchTextQuery}` : ''}`);
  };

  return (
    <div className="w-2/3 border-l py-4">
      <div className="mb-4 flex flex-col">
        <h2 className="mb-4 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Matters</h2>

        <div className="flex items-center justify-between gap-4 px-4">
          <div className="flex flex-row items-center gap-6">
            <SearchBox
              value={searchText}
              onSearchCall={() => setSearchTextQuery(searchText)}
              onChange={(v: string) => setSearchText(v)}
              placeholder="Search matters..."
            />
            {(isAdmin || isSuperAdmin) && (
              <Button
                rounded="base"
                type="brand"
                text="New Matter"
                onClick={goToNewMatter}
                icon={<FontAwesomeIcon icon={faAdd} className="mr-2 text-white" />}
              />
            )}
          </div>

          {!isLoading && responseData?.items?.length ? (
            <Pagination
              canGetNextPage={pageQuery < totalPages - 1}
              canGetPrevPage={pageQuery > 0}
              prevPage={handleGoToPreviousPage}
              nextPage={handleGoToNextPage}
              currentPage={pageQuery}
              noOfPages={totalPages}
              goToPage={goToPage}
            />
          ) : null}
        </div>
      </div>
      <div className="flex w-full flex-col gap-2 overflow-y-auto rounded px-4 pr-6">
        {isLoading ? (
          <div className="flex h-full w-full flex-col gap-4">
            <div className="flex h-full w-full flex-col gap-4">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <div key={`skeleton-${index}`} className="h-20 w-full animate-pulse rounded-md bg-gray-100" />
                ))}
            </div>
          </div>
        ) : (
          <>
            {!responseData?.items?.length ? (
              <EmptyState title="No matters found" subtitle="Create your first matter!" />
            ) : (
              responseData?.items?.map((item: MatterItem, i: number) => {
                const isCreator = item.created_by === authInfo.user?.userId;
                return (
                  <motion.div
                    key={i}
                    className="text-gray-800"
                    initial={{ opacity: 0, y: 40 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: i * 0.1 }}
                  >
                    <MatterItemComponent
                      item={item}
                      onEdit={handleClickEditMatter}
                      onView={handleClickMatterItem}
                      isCreator={isCreator}
                      isSuperAdmin={isSuperAdmin}
                    />
                  </motion.div>
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MattersPanel;
