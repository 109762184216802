import { AnimatePresence, motion } from 'framer-motion';

import ThreadsBar from '../../../ThreadsBar';
import TaggedDocumentsList from '../TaggedDocumentList';

interface QuestionSidebarProps {
  taggedDocuments: { doc_id: string; file_name: string }[];
  createNewThread: () => void;
  goToThread: (thread_id: string) => void;
}

const QuestionSidebar = ({ taggedDocuments, createNewThread, goToThread }: QuestionSidebarProps) => {
  const taggedItemsExist = taggedDocuments.length > 0;

  return (
    <div className="flex h-full w-56 min-w-[14rem] flex-col rounded-tl-lg border-r bg-white">
      <AnimatePresence>
        {taggedItemsExist && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: '33.333%', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex flex-col border-b border-dashed pb-2 pt-2"
          >
            <motion.div className="border-b pb-1 text-sm font-semibold text-gray-800">
              <p className="px-2">Tagged Items</p>
            </motion.div>
            <motion.div className="px-2 py-2">
              <TaggedDocumentsList taggedDocuments={taggedDocuments} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        layout
        className="bg-gray-50 bg-opacity-50"
        style={{ height: taggedItemsExist ? '66.666%' : '100%' }}
        transition={{ duration: 0.3 }}
      >
        <ThreadsBar createNewThread={createNewThread} goToThread={goToThread} />
      </motion.div>
    </div>
  );
};

export default QuestionSidebar;
