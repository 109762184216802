import { faComment, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import SkeletonOrComponent from '../../../../../../../components/molecules/SkeletonOrComponent';
import { ExecuteKimSearch } from '../../../types';

interface FollowUpQuestionsProps {
  followUpQuestions?: string[];
  animate: boolean;
  loading?: boolean;
  executeSearch: ExecuteKimSearch;
}

interface FollowUpQuestionsContentProps {
  followUpQuestions: FollowUpQuestionsProps['followUpQuestions'];
  animate: FollowUpQuestionsProps['animate'];
  onQuestionClick: (question: string) => void;
}

const FollowUpQuestionsContent = ({ followUpQuestions, animate, onQuestionClick }: FollowUpQuestionsContentProps) => {
  if (!followUpQuestions?.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3 rounded px-4 py-3 shadow">
      {followUpQuestions.map((question, index) => (
        <div
          className={`transform rounded px-2 transition-all duration-500 ease-out hover:bg-gray-100 ${
            animate ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
          }`}
          style={{ transitionDelay: `${index * 100}ms` }}
          key={question}
        >
          <div>
            <button
              className="flex w-full items-center justify-between rounded py-1 text-left text-sm"
              onClick={() => onQuestionClick(question)}
            >
              <p className="mr-3">{question}</p>
              <FontAwesomeIcon icon={faPlus} className="pb-1 text-brandSecondary" />
            </button>
            <div className="flex h-px flex-1 bg-gray-100" />
          </div>
        </div>
      ))}
    </div>
  );
};

const FollowUpQuestions = ({ followUpQuestions, animate, loading, executeSearch }: FollowUpQuestionsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onQuestionClick = (question: string) => {
    searchParams.delete('threadId');
    navigate(`${location.pathname}?${searchParams.toString()}`);
    executeSearch({ question });
  };

  const hasNoContent = !loading && !followUpQuestions?.length;

  if (hasNoContent) {
    return null;
  }

  return (
    <>
      <div className="mb-2 mt-6 flex items-center gap-2">
        <FontAwesomeIcon icon={faComment} className="pb-1 text-brandSecondary" />
        <h2 className="text-sm font-semibold text-gray-800">Follow up questions</h2>
      </div>

      <SkeletonOrComponent
        isLoading={loading}
        component={
          <FollowUpQuestionsContent
            animate={animate}
            followUpQuestions={followUpQuestions}
            onQuestionClick={onQuestionClick}
          />
        }
      />
    </>
  );
};

export default FollowUpQuestions;
