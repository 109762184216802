import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '../../../helpers/shadCnUtils';

interface ButtonProps {
  onClick: (() => void) | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  text: string;
  className?: string;
  icon?: React.ReactNode;
  size?: 'xs' | 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'delete' | 'brand';
  rounded?: 'sm' | 'base' | 'md' | 'lg';
  disabled?: boolean;
  loading?: boolean;
  hideTextSmallScreen?: boolean;
}

const Button = ({
  onClick,
  icon,
  text,
  className,
  size,
  type,
  rounded,
  disabled,
  loading,
  hideTextSmallScreen,
  ...props
}: ButtonProps) => {
  return (
    <button
      data-testid="button"
      className={cn(
        'flex w-fit items-center justify-center px-4 py-1 font-normal transition-colors',
        type === 'primary' && 'bg-buttonPrimary font-bold text-white',
        type === 'primary' && !disabled && 'hover:bg-buttonPrimary-hover',

        type === 'secondary' && 'border bg-buttonSecondary text-gray-800',
        type === 'secondary' && !disabled && 'hover:bg-buttonSecondary-hover',

        type === 'brand' && 'bg-brandSecondary font-bold text-white',
        type === 'brand' && !disabled && 'hover:bg-brandSecondary-hover',

        type === 'delete' && 'border border-red-600 text-red-600 shadow',
        type === 'delete' && !disabled && 'hover:bg-red-hover',

        rounded === 'base' && 'rounded',
        rounded && rounded !== 'base' && `rounded-${rounded}`,

        size === 'xs' && 'text-xs',
        size === 'small' && 'text-sm',
        size === 'medium' && 'text-base',
        size === 'large' && 'text-large',
        disabled && 'disabled:opacity-50',
        className,
      )}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-2 h-3 w-3" /> : icon ? icon : null}
      {hideTextSmallScreen ? <span className="hidden xl:inline">{text}</span> : <span className="mt-0.5">{text}</span>}
    </button>
  );
};

export default Button;
