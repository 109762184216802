import React, { useEffect, useMemo, useState } from 'react';

import { faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadcrumbHeading } from 'components/molecules/Breadcumb';
import Modal from 'components/molecules/Modals/Settings';
import PageMissing from 'components/molecules/PageMissing';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import 'moment-timezone';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { toast } from 'react-toastify';
import { ChronosRun } from 'types';

import CaseAssistant from './CaseAssistant';
import CaseSummary from './CaseSummary';
import StatusDisplay from './components/CaseProcessing';
import SettingsModalContent from './components/Modals/SettingsModal';
import NavigationTabs from './components/NavigationTabs';
import PipelineCompleteOverview from './components/PipelineCompleteOverview';
import DataView from './DataView';
import computeCaseMessage from './helpers/computeCaseMessage';
import useFetchCaseObject from './hooks/useFetchCaseObject';

export interface CaseState {
  caseName: string;
  runs: ChronosRun[];
  casePipelineStatus: string;
  queueEstimateDate: Date;
  inQueue: boolean;
  queuePosition: any; // Change this to the actual type
  matterId: string;
  estimateDate: Date;
  modalIsOpen: boolean;
  runStatusIsOpen: boolean;
  matterName: string;
}

const CaseEditor: React.FC = () => {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const [caseState, setCaseState] = useState<CaseState>({
    caseName: '',
    runs: [],
    casePipelineStatus: '',
    queueEstimateDate: new Date(),
    inQueue: false,
    queuePosition: null,
    matterId: '',
    estimateDate: new Date(),
    modalIsOpen: false,
    runStatusIsOpen: false,
    matterName: '',
  });

  const navigate = useNavigate();

  const { isLoading: isLoadingCase, data: caseData, refetch: refetchCase } = useFetchCaseObject(caseId || '');

  useEffect(() => {
    if (caseData?.caseObject) {
      if (caseData?.type === 'unauthorized') {
        toast.warning('You are not authorised to view this case...');
        navigate(`/app/chronos/matters`);
      } else if (caseData?.caseObject?.case_id) {
        setCaseState((prevState) => ({
          ...prevState,
          caseName: caseData.caseObject.case_name,
          runs: caseData.runs,
          casePipelineStatus: caseData.runs[0].pipeline_status,
          queueEstimateDate: caseData.runs[0].queue_estimate_datetime,
          inQueue: caseData.runs[0].in_queue,
          queuePosition: caseData.runs[0].queue_position || null,
          estimateDate: caseData.runs[0].time_estimate_datetime,
          matterId: caseData.caseObject.matter_id,
          matterName: caseData.caseObject.matter_name,
        }));
      }
    }
  }, [caseData, navigate]);

  // Use effect which polls the case object for updates
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (!PIPELINE_FREEZE_LOAD_STATUS.includes(caseState.casePipelineStatus)) {
      intervalId = setInterval(() => {
        refetchCase();
      }, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [caseState.casePipelineStatus, refetchCase]);

  const message = useMemo(
    () =>
      computeCaseMessage(
        caseState.inQueue,
        caseState.queuePosition,
        caseState.estimateDate,
        caseState.queueEstimateDate,
      ),
    [caseState.inQueue, caseState.queuePosition, caseState.estimateDate, caseState.queueEstimateDate],
  );

  const handleClickStatusView = () => {
    setCaseState((prev) => ({ ...prev, runStatusIsOpen: !prev.runStatusIsOpen }));
  };

  const runsInProgress = caseState.runs.filter((run: ChronosRun) => {
    return ![PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(run.pipeline_status);
  });

  const renderContent = () => {
    if (isLoadingCase) {
      return (
        <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col items-center justify-center">
          <StageSpinner className="m-auto" size={40} color={'#081D57'} />
        </div>
      );
    }

    if (
      PIPELINE_PROCESSING_STATUS.includes(caseState.casePipelineStatus) ||
      caseState.casePipelineStatus === PIPELINE_STATUS.failed.value
    ) {
      return <StatusDisplay casePipelineStatus={caseState.casePipelineStatus} message={message} />;
    }

    if ([PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(caseState.casePipelineStatus)) {
      return (
        <>
          {caseId && <NavigationTabs caseId={caseId} />}
          <Routes>
            <Route path="summary" element={<CaseSummary />} />
            <Route path="data/*" element={<DataView openSettingsModal={() => setSettingsModalOpen(true)} />} />
            <Route path="assistant" element={<CaseAssistant />} />
            <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
          </Routes>
        </>
      );
    }

    return (
      <PageMissing
        title={'Case Loading Failed'}
        description={
          <div className="mt-4 text-sm">
            Sorry! We encountered a problem loading this case. Please{' '}
            <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
              contact
            </a>{' '}
            Wexler for support.
          </div>
        }
      />
    );
  };

  return (
    <div className="flex h-full w-full flex-col items-start justify-start overflow-y-scroll text-black">
      <div className="h-full w-full bg-center px-6 pb-2 pt-4">
        {!isLoadingCase && (
          <div className="flex justify-between">
            <div className="flex items-center">
              <BreadcrumbHeading
                pages={[
                  {
                    label: <FontAwesomeIcon icon={faHome} className="h-4 w-4 text-gray-700" />,
                    href: '/app/chronos/matters',
                  },
                  {
                    label: (
                      <div>
                        {caseState.matterName.length > 30
                          ? `${caseState.matterName.substring(0, 30)}...`
                          : caseState.matterName}
                      </div>
                    ),
                    href: `/app/chronos/explore?matterId=${caseState.matterId}`,
                  },
                  {
                    label: (
                      <div className="flex items-center">
                        {caseState.caseName.length > 40
                          ? `${caseState.caseName.substring(0, 40)}...`
                          : caseState.caseName}
                        <FontAwesomeIcon
                          icon={faCog}
                          className="h-4 w-4 rounded-full p-2 text-brandPrimary hover:cursor-pointer hover:text-brandPrimary-hover"
                          onClick={() => setSettingsModalOpen(true)}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <PipelineCompleteOverview
              handleClickStatusView={handleClickStatusView}
              caseState={caseState}
              totalRuns={runsInProgress.length}
            />
          </div>
        )}
        {renderContent()}
      </div>
      <Modal
        title={
          <div className="flex items-center gap-4">
            <FontAwesomeIcon icon={faCog} className="text-gray-700" />
            Case Settings
          </div>
        }
        isOpen={settingsModalOpen}
        handleClose={() => setSettingsModalOpen(false)}
        content={<SettingsModalContent caseId={caseId as string} handleClose={() => setSettingsModalOpen(false)} />}
      />
    </div>
  );
};

export default CaseEditor;
