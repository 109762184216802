const Divider = () => <div className="flex h-8 w-1 items-center justify-center border-r"></div>;

const DataViewToolbar = ({
  titleSection,
  longTitleSection,
  actionButtons,
  searchBar,
  filterButtons,
  pagination,
}: {
  titleSection: React.ReactNode;
  longTitleSection?: boolean;
  actionButtons?: React.ReactNode;
  searchBar?: React.ReactNode;
  filterButtons?: React.ReactNode;
  pagination: React.ReactNode;
}) => {
  return (
    <div className="mb-1 h-[68px] flex-shrink-0 border-b-2 border-gray-600 border-opacity-20 pb-1">
      <div className="flex h-full justify-between pt-3">
        <div className="flex items-center gap-1">
          {/* Title Section */}
          <div className={`${longTitleSection ? 'w-fit' : 'w-36'} border-gray-600`}>{titleSection}</div>

          <Divider />

          {/* Action Buttons */}
          {actionButtons ? (
            <>
              <div className="flex w-fit items-center justify-between gap-4 px-4">{actionButtons}</div>
              <Divider />
            </>
          ) : null}
        </div>

        <div className="flex items-center gap-2">
          {/* Filters */}
          {filterButtons ? (
            <>
              <div className="flex items-center gap-2 px-2">{filterButtons}</div>
              <Divider />
            </>
          ) : null}

          {/* Search Bar */}
          {searchBar}
          <Divider />

          {/* Pagination */}
          <div className="mx-1 w-32">{pagination}</div>
        </div>
      </div>
    </div>
  );
};

export default DataViewToolbar;
