import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageMissing = ({
  title,
  description,
}: {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
}) => {
  return (
    <div className="flex h-64 flex-col items-center justify-center text-xl">
      <FontAwesomeIcon icon={faQuestionCircle} className="h-10 w-10 gap-3 text-blue-500" />
      <div className="mt-1 font-semibold">{title}</div>
      {description}
    </div>
  );
};

export default PageMissing;
