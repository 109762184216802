import { useCallback, useState } from 'react';

import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { UseQueryResult } from 'react-query';
import Swal from 'sweetalert2';

class UploadError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

interface UseUploadFiles {
  caseId: string;
  refetchCaseDocs: UseQueryResult['refetch'];
  forbiddenFileTypes?: string[];
}

const useUploadFiles = ({
  caseId,
  refetchCaseDocs,

  forbiddenFileTypes = ['.zip', '.rar'],
}: UseUploadFiles) => {
  const [successfulUploads, setSuccessfulUploads] = useState(0);
  const [docRemoving, setDocRemoving] = useState('');
  const [isUploading, setisUploading] = useState(false);
  const { getFetchConfig } = useGetFetchConfig();

  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      if (!caseId) return;

      if (forbiddenFileTypes.includes(file.name.slice(-4))) {
        Swal.fire({
          title: 'Unsupported File Type',
          text: `The file ${file.name} is not supported. Please upload supported file types.`,
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('caseId', caseId);

        const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });
        const response = await fetch(`${APIBaseChronos}/client/case/doc`, fetchConfig);

        if (!response.ok) {
          const errorData = await response.json();
          throw new UploadError(errorData.message || 'Upload failed', response);
        }
      } catch (error) {
        if (error instanceof UploadError) {
          Swal.fire({
            title: 'Error',
            text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    },
    [caseId, getFetchConfig, forbiddenFileTypes],
  );

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    await refetchCaseDocs();
    setDocRemoving('');
  };

  const onUpload = useCallback(
    async (acceptedFiles: File[]) => {
      setisUploading(true);
      setSuccessfulUploads(0);

      const uploadPromises = acceptedFiles.map(async (file) => {
        try {
          await uploadFile(file);
          setSuccessfulUploads((prev) => prev + 1);
        } catch (error) {
          if (error instanceof UploadError) {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      });

      await Promise.allSettled(uploadPromises);

      await refetchCaseDocs();
      setisUploading(false);
    },

    [uploadFile, refetchCaseDocs],
  );

  return { onUpload, isUploading, successfulUploads, handleRemoveDoc, docRemoving };
};

export default useUploadFiles;
