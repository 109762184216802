import { format } from 'date-fns';

const formatDate = ({ dateString, shortMonth }: { dateString: string; shortMonth?: boolean }) => {
  const date = new Date(dateString);
  const monthFormat = shortMonth ? 'MMM' : 'MMMM';

  return format(date, `dd ${monthFormat} yyyy`);
};

export default formatDate;
