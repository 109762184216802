import { APIBaseChronos } from 'api/hosts';
import { useQuery } from 'react-query';

export const useGetCase = (caseId: string | undefined, fetchConfigGET: RequestInit) => {
  return useQuery(['caseMetadata', caseId], () => {
    return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
      });
  });
};
