import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import { useUserContext } from 'Contexts/User';
import { cn } from 'helpers/shadCnUtils';
import moment from 'moment';
import { ChronosItem } from 'types';

interface CaseCardProps {
  item: ChronosItem;
  onClick: (itemId: string, status: string) => void;
  onDelete: (itemId: string) => void;
  isDeleting: boolean;
}

const CaseCard = ({ item, onClick, onDelete, isDeleting }: CaseCardProps) => {
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.orgSuperAdmin === 'true';
  const isOwner = user?.userId === item.created_by;

  const incompleteCase = !item.run_id;

  return (
    <div
      className={cn(
        'flex h-full flex-col items-start justify-start rounded-md border bg-white px-6 py-4 shadow',
        !item.run_id && 'border-yellow-500 border-opacity-30 bg-gray-50',
      )}
    >
      <div className="flex flex-col justify-between">
        <>
          <div className="flex w-full flex-row items-center justify-between">
            <div className="right-0 flex items-center justify-between overflow-hidden text-sm font-semibold not-italic leading-5 text-green-700">
              Created: {`${moment(item.date_created).format('MMMM DD, YYYY')}`}
            </div>
            {incompleteCase ? (
              <FontAwesomeIcon icon={faExclamationTriangle} className="h-5 w-5 text-yellow-500" />
            ) : (
              <>
                {item.dispute_status && (
                  <div className="flex h-5 items-center rounded bg-brandTertiary bg-opacity-20 px-2 text-xs font-normal text-gray-500">
                    {item.dispute_status}
                  </div>
                )}
              </>
            )}
          </div>

          <div>
            <div className="mt-2 text-lg font-semibold not-italic">
              {incompleteCase ? 'Incomplete Case Details' : item.case_name}
            </div>
          </div>

          <div className="text-xs text-gray-600">
            {incompleteCase ? (
              <p>
                This case has not been submitted yet. Click on <b>View</b> to fill out the rest of the case details and
                submit.
              </p>
            ) : item.case_legal_issues?.length > 100 ? (
              `${item.case_legal_issues.slice(0, 100)}...`
            ) : (
              <p>{item.case_legal_issues}</p>
            )}
          </div>
        </>

        <div>
          <div className="mt-4 flex gap-4">
            <Button
              rounded="md"
              size="small"
              text="View"
              type="primary"
              disabled={isDeleting}
              onClick={() => onClick(item.case_id, item.pipeline_status)}
            />
            {(isSuperAdmin || isOwner) && (
              <Button
                rounded="md"
                size="small"
                text="Delete Case"
                type="delete"
                loading={isDeleting}
                onClick={() => onDelete(item.case_id)}
              />
            )}
          </div>
          {item.editor_email && (
            <div className="mt-3 flex w-fit items-start rounded bg-yellow-200 bg-opacity-20 px-2 py-1 text-xs font-normal italic leading-4 text-gray-800">
              {`Last updated by ${item.editor_email} on ${moment(item.max_date).format('DD/MM/YYYY')}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaseCard;
