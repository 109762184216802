import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

const useFetchCaseObject = (caseId: string | undefined) => {
  const { fetchConfigGET } = useGetFetchConfig();
  return useQuery(['case', caseId], () => {
    return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
      .then((res) => res.json())
      .catch((err) => console.error(err));
  });
};

export default useFetchCaseObject;
