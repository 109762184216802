import React from 'react';

import cx from 'classnames';

interface PageWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const PageWrapper = ({ children, className }: PageWrapperProps) => {
  return (
    <div className={cx('flex h-screen w-full flex-col', className)}>
      <div className="flex h-full flex-col items-start justify-start overflow-y-auto md:flex-row">
        <div className="flex h-full w-full flex-col items-start justify-start">
          <div className="mt-10 flex w-full flex-row flex-wrap">
            <div className="flex w-full flex-row flex-wrap items-start justify-start pl-4">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
