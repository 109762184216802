import React, { useState, useCallback, useMemo } from 'react';

import { faCirclePlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import { reactSelectStylesFormFact } from 'constants/styles';
import 'react-calendar/dist/Calendar.css';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { ChronosDoc, MyOptionType } from 'types';

interface FactAdderProps {
  docs: ChronosDoc[];
  handleNewFactCreated: () => void;
  chronologyId: string;
}
const FactAdder = ({ handleNewFactCreated, docs, chronologyId }: FactAdderProps) => {
  const [selectedDoc, setSelectedDoc] = useState<MyOptionType>(null);
  const [significance, setSignificance] = useState<string>('');
  const { getFetchConfig } = useGetFetchConfig();

  const [description, setDescription] = useState<string>('');
  const [dateOfSubjectMatterText, setDateOfSubjectMatterText] = useState<string>('');

  const fetchConfig = getFetchConfig({
    method: 'POST',
    data: {
      longer_description: description,
      fact_date_text: dateOfSubjectMatterText,
      chronology_id: chronologyId,
      relevance_reason: significance,
    },
  });

  const { isFetching: isLoadingAddNewFact, refetch: refetchAddNewFact } = useQuery(
    ['addFactQuery', selectedDoc?.value],
    () => {
      return fetch(`${APIBaseChronos}/client/case/chronology/${selectedDoc?.value}`, fetchConfig)
        .then((res) => {
          toast.success('New fact created');
          handleNewFactCreated();
          return res.json();
        })
        .catch((err) => {
          console.error('Fetch Error: ', err);
          toast.error('Failed to create new fact');
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  }, []);

  const handleChangeSelectedDoc = useCallback((val: MyOptionType) => {
    setSelectedDoc(val);
  }, []);

  const handleChangeDateOfSubjectMatterText = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDateOfSubjectMatterText(e.target.value);
  }, []);

  const handleChangeSignificance = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSignificance(e.target.value);
  }, []);

  const docsOptions = useMemo(
    () =>
      docs.map((doc) => ({
        value: doc.doc_id,
        label: doc.file_name,
      })),
    [docs],
  );

  const handleAddFact = () => {
    refetchAddNewFact();
  };

  const disabled = !selectedDoc;
  return (
    <>
      {isLoadingAddNewFact ? (
        <div className="flex h-72 flex-col items-center justify-center gap-8">
          <div className="text-xl">Adding Fact...</div>
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin text-4xl text-brandSecondary" />
        </div>
      ) : (
        <div className="px-6 py-4">
          <input
            onChange={handleChangeDescription}
            className="mt-2 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={description}
            placeholder="Description"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <Select
            options={docsOptions}
            className="mt-3 w-full rounded-md bg-white outline-none"
            styles={reactSelectStylesFormFact}
            onChange={handleChangeSelectedDoc}
            value={selectedDoc}
            placeholder={'Select a document'}
          />
          <input
            onChange={handleChangeDateOfSubjectMatterText}
            className="mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={dateOfSubjectMatterText}
            placeholder="Date"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>
          <input
            onChange={handleChangeSignificance}
            className="mb-6 mt-3 h-12 w-full rounded-lg bg-white pl-3 text-base font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
            value={significance}
            placeholder="Significance"
            style={{ border: '1px solid #ECEFFF' }}
          ></input>

          <Button
            onClick={() => {
              !disabled && handleAddFact();
            }}
            text="Add"
            type="primary"
            rounded="md"
            icon={<FontAwesomeIcon icon={faCirclePlus} className="mr-2" />}
          />
        </div>
      )}
    </>
  );
};

export default FactAdder;
