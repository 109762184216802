import { useState } from 'react';

import { trackCustomEvent } from 'analytics/Mixpanel';
import Copy from 'components/atoms/Copy';
import Download from 'components/atoms/Download';

import useDownloadAnswer from '../../../hooks/useDownloadAnswer';
import { ConfidenceLevelObject, Artifact, KimMode, Message } from '../../../types';
import ConfidenceChip from '../ConfidenceChip';

interface AnswerHeaderProps {
  confidenceLevel: ConfidenceLevelObject;
  answerMessage: Message | undefined;
  setShowConfidenceTooltip: (show: boolean) => void;
  caseId: string;
  threadId: string;
  artifact: Artifact | undefined;
  flowType: KimMode | undefined;
}

const AnswerHeader: React.FC<AnswerHeaderProps> = ({
  confidenceLevel,
  answerMessage,
  setShowConfidenceTooltip,
  caseId,
  threadId,
  artifact,
  flowType,
}) => {
  const [downloadStart, setDownloadStart] = useState(false);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const { refetch: fetchDownloadAnswer } = useDownloadAnswer(caseId, threadId, artifact);

  const handleDownloadAnswer = async () => {
    trackCustomEvent('Download Kim Answer');
    setDownloadStart(true);
    try {
      await fetchDownloadAnswer();
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      // Reset state
      setDownloadStart(false);
      setDownloadComplete(true);
      setTimeout(() => setDownloadComplete(false), 1000);
    }
  };

  return (
    <div className="flex items-center justify-between gap-10">
      <div className="flex items-center gap-4">
        {confidenceLevel.text !== 'Low' && (
          <>
            {flowType !== 'extraction_flow' && (
              <Copy
                copyHandler={() => {
                  trackCustomEvent('Kim Copy Answer');
                  const cleanedText = answerMessage
                    ? answerMessage.message_content.replace(/\s*\[[\d,\s]+\]\s*/g, '')
                    : '';
                  navigator.clipboard.writeText(cleanedText);
                }}
                variant="icon"
              />
            )}
            <Download
              handleDownload={handleDownloadAnswer}
              downloadStart={downloadStart}
              downloadComplete={downloadComplete}
              variant="icon"
            />
          </>
        )}
      </div>
      <ConfidenceChip
        confidenceLevel={confidenceLevel}
        onClick={() => {
          trackCustomEvent('Kim Confidence Level Clicked');
          setShowConfidenceTooltip(true);
        }}
      />
    </div>
  );
};

export default AnswerHeader;
