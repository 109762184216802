import isPlural from 'helpers/isPlural';
import { useSearchParams } from 'react-router-dom';
import { DocumentChunk as Chunk } from 'types';

import { SupportingEvidenceProps } from '.';
import { Message } from '../../../types';
import DocumentChunk from '../DocumentChunk';

interface ShowSupportingTextsProps {
  answerMessage: Message;
  setSelectedChunk: SupportingEvidenceProps['setSelectedChunk'];
  setShowAllChunks: SupportingEvidenceProps['setShowAllChunks'];
  showAllChunks: SupportingEvidenceProps['showAllChunks'];
}

const ShowSupportingTexts = ({
  answerMessage,
  setSelectedChunk,
  showAllChunks,
  setShowAllChunks,
}: ShowSupportingTextsProps) => {
  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();
  const handleSelectChunk = (chunk: Chunk) => {
    setSelectedChunk(chunk);
    // Add chunkId and docId to URL
    setSearchParams((prev) => {
      prev.set('chunkId', chunk.chunk_id);
      prev.set('docId', chunk.document_id);
      return prev;
    });
  };

  return (
    <div className="my-2 mt-4 text-xs">
      {answerMessage?.chunks.length > 0 && (
        <button
          className="my-1 text-sm font-medium text-blue-600 hover:text-blue-800"
          onClick={() => setShowAllChunks(!showAllChunks)}
        >
          {showAllChunks
            ? 'Hide additional supporting texts'
            : `Show ${answerMessage?.chunks.length} supporting ${isPlural({
                one: 'text',
                more: 'texts',
                number: answerMessage?.chunks.length,
              })}...`}
        </button>
      )}
      <div className="flex flex-col gap-2">
        {answerMessage?.chunks.slice(0, showAllChunks ? undefined : 0).map((chunk) => (
          <div key={chunk.chunk_id} id={chunk.chunk_id}>
            <DocumentChunk chunk={chunk} handleSelectChunk={handleSelectChunk} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ShowSupportingTexts;
