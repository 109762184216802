import React from 'react';

interface ActionButtonProps {
  title: string;
  description: string | React.ReactNode;
  icon?: React.ReactNode;
  onClick: () => void;
  index?: number;
}

const ActionButton = ({ title, description, icon, onClick, index }: ActionButtonProps) => {
  return (
    <div className={`shimmer-border ${index ? `border-active-${index}` : ''}`} onClick={onClick}>
      <div className="flex w-full items-center gap-2 rounded-full border bg-gray-50 from-blue-50 to-gray-50 px-4 py-2 hover:cursor-pointer hover:bg-gradient-to-r">
        {icon}
        <div>
          <p className="text-blue-500">{title}</p>
          <p className="text-xs text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ActionButton;
