import React, { Fragment, useState } from 'react';

import { faFilePdf, faFolderPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '@ramonak/react-progress-bar';
import Button from 'components/atoms/Button';
import { TooltipStyles } from 'constants/styles';
import isPlural from 'helpers/isPlural';
import { Accept, ErrorCode, FileWithPath, useDropzone } from 'react-dropzone';
import { Tooltip } from 'react-tooltip';

interface FileUploaderProps {
  isUploading: boolean;
  onDrop: (acceptedFiles: File[]) => void;
  successfulUploads?: number;
  acceptedTypes?: Accept;
  maxFiles?: number;
  maxSize?: number;
  tooltipText: string;
  showPreview?: boolean;
}

interface FileUploadInputContentProps {
  isUploading: FileUploaderProps['isUploading'];
  maxFiles: FileUploaderProps['maxFiles'];
  tooltipText: FileUploaderProps['tooltipText'];
  showPreview: FileUploaderProps['showPreview'];
  successfulUploads: FileUploaderProps['successfulUploads'];
  acceptedFiles: readonly FileWithPath[];
  files: FileWithPreview[];
  onDeleteClick: (index: number) => void;
  isDragActive: boolean;
}

const getErrorMessage = ({
  errorCode,
  maxFiles,
  maxSize,
  acceptedFileTypes,
}: {
  errorCode: ErrorCode;
  acceptedFileTypes?: Accept;
  maxFiles?: number;
  maxSize?: number;
}) => {
  const errorMessages: Record<ErrorCode, string> = {
    'file-invalid-type': acceptedFileTypes
      ? `File needs to be one of ${Object.values(acceptedFileTypes).join(', ')}`
      : 'Invalid file type',
    'file-too-large': maxSize ? `Maximum file size is ${maxSize / 10000} MB` : 'File too large',
    'file-too-small': 'File too small',
    'too-many-files': maxFiles
      ? `Only ${maxFiles} ${isPlural({ one: 'file', more: 'files', number: maxFiles })}`
      : 'Too many files',
  };

  return errorMessages[errorCode];
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 50,
  height: 50,
  padding: 4,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

interface FileWithPreview extends File {
  preview: string;
}

const FileUploadInputContent = ({
  isUploading,
  isDragActive,
  maxFiles,
  tooltipText,
  showPreview,
  files,
  onDeleteClick,
  acceptedFiles,
  successfulUploads = 0,
}: FileUploadInputContentProps) => {
  const thumbs = files.map((file, index) => {
    return file.type.includes('pdf') ? (
      <Fragment key={file.name}>
        <div className="relative mr-4 h-full w-12">
          <FontAwesomeIcon
            className="absolute -top-2 right-2 hover:text-gray-500"
            icon={faTimesCircle}
            onClick={() => onDeleteClick(index)}
          />
          <FontAwesomeIcon icon={faFilePdf} className="mr-4 h-1/3 text-gray-500 transition-transform duration-300" />
          <p className="truncate text-xs">{file.name}</p>
        </div>
      </Fragment>
    ) : (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            alt={file.name}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    );
  });

  if (isDragActive) {
    if (maxFiles === files.length) {
      return <p className="text-red-400">Maximum amount of files reached, remove a file first</p>;
    }
    return <p className="text-blue-500">Drop the files here ...</p>;
  }

  if (isUploading) {
    return (
      <div className="flex flex-col">
        <div className="mb-4 text-center text-base font-bold not-italic leading-6 text-gray-900">
          Uploaded {successfulUploads} of {acceptedFiles.length} files
        </div>
        <ProgressBar
          completed={(100 * successfulUploads) / acceptedFiles.length}
          customLabel={''}
          bgColor={'#4161FF'}
          height={'6px'}
          className="animate-pulse"
          isLabelVisible={false}
        />
      </div>
    );
  }

  if (files.length && showPreview) {
    return <aside className="z-10 flex flex-1 justify-center">{thumbs}</aside>;
  }

  return (
    <div>
      <Button
        type="brand"
        rounded="md"
        text={isPlural({ one: 'Upload File', more: 'Upload Files', number: maxFiles || 0 })}
        onClick={() => null}
        data-tooltip-id="agreed-tooltip"
        data-tooltip-target="tooltip-hover"
        data-tooltip-trigger="hover"
        data-tooltip-content={tooltipText}
        icon={<FontAwesomeIcon icon={faFolderPlus} className="mr-2 text-white" />}
      />
      <Tooltip id="agreed-tooltip" style={TooltipStyles} />
      <p className="mt-3 text-center text-sm not-italic leading-4 text-gray-700">Or drag here</p>
    </div>
  );
};

const FileUploadInput: React.FC<FileUploaderProps> = ({
  isUploading,
  onDrop,
  acceptedTypes,
  tooltipText,
  maxFiles,
  maxSize,
  successfulUploads = 1,
  showPreview,
}) => {
  const [localError, setLocalError] = useState<ErrorCode | null>(null);
  const [files, setFiles] = useState<FileWithPreview[]>([]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections = [],
  } = useDropzone({
    multiple: maxFiles !== 1,
    noClick: maxFiles === files.length,
    onDropAccepted: (acceptedFiles) => {
      setLocalError(null);
      if (maxFiles === files.length) {
        return setLocalError(ErrorCode.TooManyFiles);
      }
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFiles([...files, ...newFiles]);
      onDrop(acceptedFiles);
    },
    accept: acceptedTypes,
    maxSize,
    maxFiles,
  });
  const onDeleteClick = (index: number) => {
    const newFiles = files.filter((file, i) => i !== index);

    setFiles(newFiles);
  };

  return (
    <div
      data-testid="file-uploader"
      className={`relative mt-2 flex h-32 items-center justify-center rounded-2xl border border-dashed border-brandSecondary bg-slate-100 ${
        maxFiles === files.length ? 'cursor-default' : 'cursor-pointer'
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <FileUploadInputContent
        isUploading={isUploading}
        isDragActive={isDragActive}
        maxFiles={maxFiles}
        tooltipText={tooltipText}
        showPreview={showPreview}
        files={files}
        acceptedFiles={acceptedFiles}
        successfulUploads={successfulUploads}
        onDeleteClick={onDeleteClick}
      />
      <div className="absolute bottom-2 left-3">
        <p
          className={`h-4 text-xs text-red-400 transition-opacity ${
            fileRejections[0] || localError ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {getErrorMessage({
            errorCode: localError || (fileRejections[0]?.errors[0].code as ErrorCode),
            acceptedFileTypes: acceptedTypes,
            maxFiles,
            maxSize,
          })}
        </p>
      </div>
    </div>
  );
};

export default FileUploadInput;
