import { useMemo } from 'react';

import { ColumnDef } from '@tanstack/react-table';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import { ChronosRun } from 'types';

import DateCell from './DateCell';

export const columnLabels: Record<string, string> = {};

const useGetDocumentColumns = () => {
  const columns = useMemo<ColumnDef<ChronosRun, any>[]>(
    () => [
      {
        header: () => <div className="">Run</div>,
        size: 50,
        accessorKey: 'item_number',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { item_number },
            },
          },
        }: any) => <div className="flex w-10 flex-row items-center justify-start pl-2">{item_number + 1}</div>,
      },
      {
        header: () => <div className="">Status</div>,
        accessorKey: 'pipeline_status',
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: {
              original: { pipeline_status, in_queue, queue_position },
            },
          },
        }: any) => (
          <div className="w-44">
            {in_queue ? `In queue, position ${queue_position}` : PIPELINE_STATUS[pipeline_status]?.label}
          </div>
        ),
      },
      {
        id: 'estimatedEndTime',
        header: () => <div className="min-w-[150px]">Estimated End Time</div>,
        accessorKey: 'time_estimate_datetime',
        enableColumnFilter: false,
        cell: ({ row }) =>
          row.original.pipeline_status !== 'complete' ? (
            <DateCell format={'DD MMMM YYYY'} date={row.getValue('estimatedEndTime')} />
          ) : (
            ''
          ),
      },
    ],

    [],
  );
  return columns;
};

export default useGetDocumentColumns;
