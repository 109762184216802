import { memo, useEffect, useState } from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface PaginationProps {
  canGetNextPage: boolean;
  canGetPrevPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  currentPage: number;
  noOfPages: number;
  goToPage?: (value: number) => void;
  isLoadingFacts?: boolean;
}

const Pagination = ({
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  goToPage,
}: PaginationProps) => {
  const [currentPageString, setCurrentPageString] = useState('1');

  useEffect(() => {
    if (currentPage !== parseInt(currentPageString) - 1) {
      setCurrentPageString((currentPage + 1).toString());
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const handleChangePage = (value: string) => {
    setCurrentPageString(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(currentPageString) - 1;
      goToPage && goToPage(pageNumber);
    }
  };

  return (
    <div className="flex items-center justify-between text-xs">
      <button
        className="mr-1 flex cursor-pointer items-center rounded-full bg-gray-100 p-1 shadow-sm hover:opacity-80 disabled:cursor-not-allowed"
        onClick={prevPage}
        disabled={!canGetPrevPage}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          className="h-3 w-3 text-gray-700"
          style={{ color: canGetPrevPage ? 'gray' : 'white' }}
        />
      </button>

      <p className="flex flex-row items-center justify-center text-center">
        <input
          value={currentPageString}
          className="mx-1 h-5 w-8 rounded border px-1"
          onChange={(e) => handleChangePage(e.target.value)}
          onKeyDown={handleKeyDown}
          inputMode="numeric"
          min="1"
        />
        of {noOfPages}
      </p>
      <button
        className={`ml-1 flex cursor-pointer items-center rounded-full bg-gray-100 p-1 shadow-sm hover:opacity-80 disabled:cursor-not-allowed`}
        onClick={nextPage}
        disabled={!canGetNextPage}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className="h-3 w-3 text-gray-700"
          style={{ color: canGetNextPage ? 'gray' : 'white' }}
        />
      </button>
    </div>
  );
};

export default memo(Pagination);
