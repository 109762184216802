import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { FetchFactsByDoc, FetchFactsByDocRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

function useFactsData({ docId, caseId, searchQuery, filters, page, pageSize }: FetchFactsByDoc) {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<FetchFactsByDocRes, Error>(
    [QueryEntity.CaseEvent, { caseId }],
    async () => {
      const encodedSearchQuery = searchQuery ? encodeURIComponent(searchQuery) : '';

      const filterParams = Object.keys(filters)
        .map((key) => {
          const values = filters[key];
          if (Array.isArray(values)) {
            return `filter_${key}=${values.map((val) => val.value).join(',')}`;
          }
          return `filter_${key}=${values}`;
        })
        .join('&');

      const response = await fetch(
        `${APIBaseChronos}/client/case/fact/${caseId}?docId=${docId}&searchQuery=${encodedSearchQuery}&page=${page}&pageSize=${pageSize}&sort_date_of_subject=asc&${filterParams}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { refetchOnWindowFocus: false },
  );
}

export default useFactsData;
