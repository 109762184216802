import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';

const useDeleteThread = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  const deleteThread = async (threadId: string) => {
    const fetchConfig = getFetchConfig({
      method: 'DELETE',
    });

    const response = await fetch(`${APIBaseChronos}/client/case/assistant/thread/${caseId}/${threadId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Creating thread failed');
    }

    return response.json();
  };

  return useMutation({
    mutationFn: (threadId: string) => deleteThread(threadId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['threads', caseId] });
    },
  });
};

export default useDeleteThread;
