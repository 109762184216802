import { faBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FactDropdown from 'components/molecules/FactDropdown';
import SkeletonOrComponent from 'components/molecules/SkeletonOrComponent';
import { useUserContext } from 'Contexts/User';
import { ChronosFact, DocumentChunk as Chunk } from 'types';

import ShowSupportingTexts from './ShowSupportingText';
import { ConfidenceLevelObject, Message } from '../../../types';

export interface SupportingEvidenceProps {
  loading?: boolean;
  animate: boolean;
  answerMessage?: Message;
  confidenceLevel: ConfidenceLevelObject;
  facts: ChronosFact[];
  showAllChunks: boolean;
  setShowAllChunks: (bool: boolean) => void;
  updateFactData: (eventId: string, data: Partial<ChronosFact>) => void;
  setSelectedChunk: (chunk: Chunk) => void;
}

interface SupportingEvidenceContentProps {
  facts: SupportingEvidenceProps['facts'];
  answerMessage: SupportingEvidenceProps['answerMessage'];
  animate: SupportingEvidenceProps['animate'];
  updateFactData: SupportingEvidenceProps['updateFactData'];
}

const SupportingEvidenceContent = ({
  facts,
  answerMessage,
  animate,
  updateFactData,
}: SupportingEvidenceContentProps) => {
  const { user } = useUserContext();
  if (!answerMessage || answerMessage.facts.length === 0) {
    return null;
  }
  return (
    <div className="mb-2">
      <div className="flex flex-col gap-2">
        {facts.map((fact, index) => {
          return (
            <div
              className={`transform rounded border-2 transition-all duration-500 ease-out ${
                animate ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
              }`}
              style={{ transitionDelay: `${index * 100}ms` }}
              key={fact.event_id}
              id={fact.event_id}
            >
              <FactDropdown
                fact={fact}
                userId={user?.userId || ''}
                compressedVersion={true}
                reference={fact.reference}
                hideRelevanceIndicator={true}
                updateFactData={updateFactData}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SupportingEvidence = ({
  loading,
  confidenceLevel,
  answerMessage,
  facts,
  animate,
  setShowAllChunks,
  showAllChunks,
  updateFactData,
  setSelectedChunk,
}: SupportingEvidenceProps) => {
  const hasNoContent =
    !loading && (!answerMessage || (answerMessage?.facts.length === 0 && answerMessage?.chunks.length === 0));

  if (hasNoContent) {
    return null;
  }

  return (
    <div className="mt-6">
      <div className="mb-2 flex items-center gap-2">
        <FontAwesomeIcon icon={faBook} className="pb-1 text-brandSecondary" />
        <h2 className="text-sm font-semibold text-gray-800">
          {confidenceLevel.text === 'Low' ? 'Possible Information' : 'Supporting Evidence'}
        </h2>
      </div>
      <SkeletonOrComponent
        isLoading={loading}
        component={
          <SupportingEvidenceContent
            facts={facts}
            answerMessage={answerMessage}
            animate={animate}
            updateFactData={updateFactData}
          />
        }
        skeletonHeight="h-12"
      />

      {loading || !answerMessage ? null : answerMessage?.chunks &&
        Array.isArray(answerMessage.chunks) &&
        answerMessage.chunks.length > 0 ? (
        <ShowSupportingTexts
          setSelectedChunk={setSelectedChunk}
          setShowAllChunks={setShowAllChunks}
          showAllChunks={showAllChunks}
          answerMessage={answerMessage}
        />
      ) : null}
    </div>
  );
};

export default SupportingEvidence;
