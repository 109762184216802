import { faArrowLeft, faDownload, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import Pagination, { PaginationProps } from 'components/organisms/Pagination';
import DataViewToolbar from 'screens/Chronos/CaseEditor/DataView/components/TableToolbar';
// import SearchBox from '../../components/SearchBox';

interface ChronologyTableToolbarProps {
  globalFilter: string;
  chronologyTitle: string;
  setGlobalFilter: (value: string) => void;
  handleBack: () => void;
  openModal: () => void;
  openDownloadModal: () => void;
  goToPage: (value: number) => void;
  onSearchCall: () => void;
}

const ChronologyTableToolbar = ({
  globalFilter,
  setGlobalFilter,
  chronologyTitle,
  handleBack,
  openModal,
  openDownloadModal,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  goToPage,
  onSearchCall,
}: ChronologyTableToolbarProps & PaginationProps) => {
  return (
    <DataViewToolbar
      titleSection={
        <div className="flex items-center pr-4">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="ml-2 mr-3 h-3 w-3 cursor-pointer rounded-full border bg-gray-50 p-1 text-gray-700"
            onClick={handleBack}
          />
          <div className="font-semibold text-blue-500">{chronologyTitle}</div>
        </div>
      }
      longTitleSection={true}
      actionButtons={
        <>
          <Button
            icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
            className="rounded border bg-buttonSecondary px-2 py-2 text-xs shadow hover:bg-buttonSecondary-hover"
            onClick={openModal}
            text="Add Fact"
          />

          <Button
            icon={<FontAwesomeIcon icon={faDownload} className="pr-2" />}
            className="rounded border bg-buttonSecondary px-2 py-2 text-xs shadow hover:bg-buttonSecondary-hover"
            onClick={openDownloadModal}
            text="Download"
          />
        </>
      }
      searchBar={
        // TODO: Add back search bar, temporarily removed
        // <SearchBox
        //   value={globalFilter}
        //   placeholder={'Search chronology'}
        //   onChange={setGlobalFilter}
        //   onSearchCall={onSearchCall}
        // />
        <></>
      }
      pagination={
        <Pagination
          canGetPrevPage={canGetPrevPage}
          canGetNextPage={canGetNextPage}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={noOfPages}
          goToPage={goToPage}
        />
      }
    />
  );
};

export default ChronologyTableToolbar;
