import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';

import Modal, { ModalProps } from '../Custom';

interface ConfirmationModalProps extends Omit<ModalProps, 'content'> {
  title: string;
  description: string;
  onConfirm: () => void;
  isLoading: boolean;
  confirmButtonText?: string;
}

const ConfirmationModal = ({
  title,
  description,
  onConfirm,
  confirmButtonText = 'Delete',
  isLoading,
  ...props
}: ConfirmationModalProps) => {
  return (
    <Modal
      {...props}
      content={
        <div className="flex flex-col items-center justify-center">
          <div className="w-full bg-gray-200 px-8 py-4">
            <p className="text-lg font-semibold">{title}</p>
          </div>
          <div className="w-full px-8 py-6">
            <div className="w-full">
              {isLoading ? (
                <div className="flex w-full justify-center p-2 text-gray-500">
                  <FontAwesomeIcon icon={faCircleNotch} className="h-6 w-6 animate-spin" />
                </div>
              ) : (
                <p className="text-gray-700">{description}</p>
              )}
            </div>
            <div className="flex w-full items-center justify-between pb-2 pt-8">
              <Button disabled={isLoading} onClick={onConfirm} text={confirmButtonText} type="delete" rounded="md" />
              <Button disabled={isLoading} onClick={props.handleClose} text="Cancel" type="secondary" rounded="md" />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ConfirmationModal;
