import { faQuestion, faTimeline, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { useLocation, useNavigate } from 'react-router-dom';

import ActionButton from '../ActionButton';

const ExploreSection = ({ setChronologyExplorerOpen }: { setChronologyExplorerOpen: (bool: boolean) => void }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const caseId = searchParams.get('caseId');

  const handleExploreClicked = () => {
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('explore', 'true');
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  return (
    <div className="relative w-5/12 rounded-lg border-2 border-gray-200 border-opacity-40 bg-white p-4 py-4">
      <h2 className="mb-2 border-2 border-x-0 border-t-0 pb-1 font-semibold">Explore</h2>
      <div className="flex w-full flex-col gap-4 text-sm text-gray-800">
        <ActionButton
          icon={
            <FontAwesomeIcon
              icon={faQuestion}
              className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
            />
          }
          title="Ask a question"
          description="Use our AI agent, Kim, to ask focused questions about the case."
          onClick={() => {
            trackCustomEvent('Ask Kim button on summary page');
            navigate('/app/chronos/case-editor/assistant?caseId=' + caseId);
          }}
          index={0}
        />

        <ActionButton
          icon={
            <FontAwesomeIcon
              icon={faTimeline}
              className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
            />
          }
          title="Create chronology"
          description="Construct a timeline of key events."
          onClick={() => {
            trackCustomEvent('Create chronology action button on summary page');
            setChronologyExplorerOpen(true);
          }}
          index={1}
        />
        <ActionButton
          icon={
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="mr-2 h-4 w-4 cursor-pointer rounded-full border p-2 text-gray-800"
            />
          }
          title="Explore the facts"
          description="Explore, review and edit all of the extracted facts."
          onClick={() => {
            trackCustomEvent('Explore the facts action button on summary page');
            handleExploreClicked();
          }}
          index={2}
        />
      </div>
    </div>
  );
};
export default ExploreSection;
