import React from 'react';
const LiabilityDisclaimer = () => {
  return (
    <div className="flex flex-col">
      <div className="flex h-screen flex-col items-start justify-start pt-7 text-black md:flex-row">
        <div className="flex h-full w-full flex-col items-start justify-start px-10 text-base">
          <h1 className="my-10 text-4xl">EXCLUSION OF LIABILITY</h1>
          <div className="mb-10">
            1. The following Exclusion of Liability is agreed to cover pilot usage of Wexler.ai (“the product”). The
            product is a piece of software designed to help lawyers to undertake routine tasks. At its centre is a
            powerful search utility but it has various other functions, including the filing, recording, sorting and
            memorising of information, and the preparation of drafts of documents such as letters, memoranda, agreements
            and attendance notes. It is in the early stages of its development and like all pieces of software will be
            progressively improved and enhanced over time.
          </div>
          <div className="mb-10">
            2. The provider emphasises to the user that the product cannot form judgements, exercise discretion,
            undertake subjective analysis or perform any other role that requires a human contribution. While the
            product possesses “intelligence” in the sense that it has much knowledge it has no wisdom or consciousness
            (and never will have any).
          </div>
          <div className="mb-10">
            3. The output of search or other instructions given to the product depends critically on the quality of
            those instructions. Instructions should not call on the product to devise strategy for a client or otherwise
            exercise subjective judgment as to the merits of a course of action. Instructions should recognise and
            reflect the functional limitations of the product as explained above.
          </div>
          <div className="mb-10">
            4. Even if the instructions fall within the functional limitations of the product, and are very specific, it
            is entirely possible that the output will be factually or legally erroneous. The user should be particularly
            cautious of legal research made by the product.
          </div>
          <div className="mb-10">
            5. It is therefore emphasised by the provider to the user that no reliance should be placed on the product’s
            output unless it has been checked by a lawyer.
          </div>
          <div className="mb-10">
            6. Accordingly the user accepts and agrees that it will not under any circumstances hold the provider liable
            for any loss or damage of any nature which a client of the user may claim to have suffered as a result of
            advice given by the user to that client, or steps taken by the user on behalf of that client, based on any
            output of the product.
          </div>
          <div className="mb-10">
            7. This exclusion of liability is intended to apply in the widest sense possible sense and shall cover all
            causes of action of any nature.
          </div>
          <div className="mb-10">
            8. The provider and the user recognise that an exclusion clause only has effect inasmuch as it is
            objectively reasonable. The user by this agreement acknowledges that the terms of the exclusion are
            objectively reasonable and will not seek to argue otherwise in any circumstances.
          </div>
          <div className="mb-10">9. This agreement is governed by the law of England.</div>
        </div>
      </div>
    </div>
  );
};

export default LiabilityDisclaimer;
