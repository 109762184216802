import Button from 'components/atoms/Button';

import { UserObject } from '../types/user';

interface UserListInterface {
  users: UserObject[];
  onRemove: (userId: string) => void;
}

const UserList = ({ users, onRemove }: UserListInterface) => {
  return (
    <div className="mt-6 flex h-48 w-full flex-col gap-4 overflow-y-scroll rounded-lg border bg-gray-100 p-2 text-sm">
      {users?.map((user: any) => {
        return (
          <div className="h-fit w-full rounded bg-white px-2 py-1" key={user.user_id}>
            <div className="flex items-center justify-between">
              <div className={`w-full font-medium not-italic text-black`}>{user.user_email}</div>
              <Button onClick={() => onRemove(user.user_id)} text="Remove" rounded="md" type="primary" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserList;
