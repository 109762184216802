import { ReactElement } from 'react';

interface SkeletonOrComponentProps {
  component: ReactElement;
  skeletonHeight?: string;
  isLoading?: boolean;
}

const SkeletonOrComponent = ({ isLoading, component, skeletonHeight = 'h-8' }: SkeletonOrComponentProps) => {
  if (isLoading) {
    return (
      <div className="flex flex-col gap-3 rounded px-4 py-3 shadow">
        <div className={`w-full animate-pulse rounded-xl bg-gray-100 ${skeletonHeight}`} />
        <div className={`w-full animate-pulse rounded-xl bg-gray-100 ${skeletonHeight}`} />
        <div className={`w-full animate-pulse rounded-xl bg-gray-100 ${skeletonHeight}`} />
        <div className={`w-4/5 animate-pulse rounded-xl bg-gray-100 ${skeletonHeight}`} />
      </div>
    );
  }

  return component;
};

export default SkeletonOrComponent;
