import React from 'react';

import { ReactComponent as EmptyBoxIcon } from 'assets/icons/empty-box.svg';

interface EmptyStateProps {
  title: string;
  subtitle?: string;
  icon?: React.ReactNode;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
  icon = <EmptyBoxIcon className="h-16 w-16 text-gray-700" />,
}) => {
  return (
    <div className="flex h-80 w-full items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-1">
          <p className="text-xl text-gray-600">{title}</p>
          {subtitle && <p className="text-gray-400">{subtitle}</p>}
        </div>
        {icon}
      </div>
    </div>
  );
};

export default EmptyState;
