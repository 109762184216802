import React from 'react';

import { ReactComponent as ReactInfo } from 'assets/icons/info.svg';
import { TooltipStyles } from 'constants/styles';
import { Tooltip, PlacesType } from 'react-tooltip';

interface TooltipIconProps {
  tooltipId: string;
  tooltipContent: string;
  className?: string;
  style?: React.CSSProperties;
  place?: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = React.memo(({ tooltipId, tooltipContent, className, style, place }) => (
  <>
    <ReactInfo
      data-tooltip-id={tooltipId}
      className={`mr-2 cursor-pointer text-sm text-gray-700 ${className}`}
      style={style}
    />
    <Tooltip
      opacity={1}
      id={tooltipId}
      content={tooltipContent}
      style={TooltipStyles}
      place={(place as PlacesType) || ''}
    />
  </>
));

export default TooltipIcon;
