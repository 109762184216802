import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { StatsByMatter, StatsByUser } from 'api/types/queries';
import TableFilterPopup from 'components/atoms/TableFilterPopup';
import { FilterOption } from 'types';

interface Column {
  header: string;
  accessorKey: string;
}

interface StatsTableProps {
  columns: Column[];
  rows: StatsByMatter[] | StatsByUser[];
  searchFilter: string;
  toggleGroupBy: (value: string) => void;
  chosenOption: FilterOption;
}

const StatisticsTable = ({ columns, rows, searchFilter, toggleGroupBy, chosenOption }: StatsTableProps) => {
  const table = useReactTable<StatsByMatter | StatsByUser>({
    columns: columns.map((column) => {
      if (column.accessorKey === 'matterName') {
        return {
          ...column,
          cell: ({ row }) => (
            <div>
              <div>{(row.original as StatsByMatter).matterName}</div>
              <div className="text-xs text-gray-500">{(row.original as StatsByMatter).matterCode}</div>
            </div>
          ),
        };
      }
      return column;
    }),
    data: rows,
    state: { globalFilter: searchFilter },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <table className="w-full text-gray-700">
      <thead className="sticky h-12 bg-gray-100">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={` ${header.index === 0 ? 'pl-8 text-start' : 'text-end'} py-4 ${header.index === columns.length - 1 ? 'pr-8' : ''} `}
              >
                {header.column.id === 'matterName' || header.column.id === 'userEmail' ? (
                  <div className="flex items-center gap-1">
                    <TableFilterPopup
                      options={[
                        { label: 'Matter', value: 'matter' },
                        { label: 'User', value: 'user' },
                      ]}
                      onChange={toggleGroupBy}
                      placeholder="Group by"
                      chosenOption={chosenOption}
                    />
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                ) : (
                  flexRender(header.column.columnDef.header, header.getContext())
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            className={`h-24 border border-x-0 border-gray-200 ${row.index % 2 === 0 ? 'white' : 'bg-gray-100'}`}
          >
            {row.getVisibleCells().map((cell, index) => (
              <td
                key={cell.id}
                className={` ${index === 0 ? 'pl-8 text-start' : 'text-end'} w-1/12 ${index === columns.length - 1 ? 'pr-8' : ''} `}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StatisticsTable;
