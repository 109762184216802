import {
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FloatingControls = ({
  GoToFirstPage,
  GoToPreviousPage,
  CurrentPageInput,
  GoToNextPage,
  GoToLastPage,
  ZoomIn,
  ZoomOut,
}: {
  GoToFirstPage: React.ComponentType<any>;
  GoToPreviousPage: React.ComponentType<any>;
  CurrentPageInput: React.ComponentType<any>;
  GoToNextPage: React.ComponentType<any>;
  GoToLastPage: React.ComponentType<any>;
  ZoomIn: React.ComponentType<any>;
  ZoomOut: React.ComponentType<any>;
}) => {
  return (
    <>
      <div className="absolute bottom-2 left-0 flex items-center justify-center gap-1 rounded-full border bg-gray-50 px-1 text-gray-700">
        <GoToFirstPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
            </button>
          )}
        </GoToFirstPage>
        <GoToPreviousPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
            </button>
          )}
        </GoToPreviousPage>
        <CurrentPageInput />
        <GoToNextPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
            </button>
          )}
        </GoToNextPage>
        <GoToLastPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
            </button>
          )}
        </GoToLastPage>
      </div>
      <div className="absolute bottom-2 right-2 flex items-end justify-center gap-1 rounded-full border bg-gray-50 px-1 py-1 text-gray-700">
        <ZoomIn>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </button>
          )}
        </ZoomIn>
        <ZoomOut>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
            </button>
          )}
        </ZoomOut>
      </div>
    </>
  );
};

const HeaderControls = ({
  GoToFirstPage,
  GoToPreviousPage,
  CurrentPageInput,
  GoToNextPage,
  GoToLastPage,
  ZoomIn,
  ZoomOut,
}: {
  GoToFirstPage: React.ComponentType<any>;
  GoToPreviousPage: React.ComponentType<any>;
  CurrentPageInput: React.ComponentType<any>;
  GoToNextPage: React.ComponentType<any>;
  GoToLastPage: React.ComponentType<any>;
  ZoomIn: React.ComponentType<any>;
  ZoomOut: React.ComponentType<any>;
}) => {
  return (
    <>
      <div className="flex items-center justify-center gap-1 rounded-full border bg-gray-50 px-1 text-gray-700">
        <GoToFirstPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
            </button>
          )}
        </GoToFirstPage>
        <GoToPreviousPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronLeft} className="h-3 w-3" />
            </button>
          )}
        </GoToPreviousPage>
        <div className="hidden xl:flex">
          <CurrentPageInput />
        </div>
        <GoToNextPage>
          {(props: any) => (
            <button className="flex rounded-full p-2 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
            </button>
          )}
        </GoToNextPage>
        <GoToLastPage>
          {(props: any) => (
            <button
              className="flex items-center justify-center rounded-full p-2 hover:bg-gray-200"
              onClick={props.onClick}
            >
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
              <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3" />
            </button>
          )}
        </GoToLastPage>
      </div>

      <div className="flex items-end justify-center gap-1 rounded-full border bg-gray-50 text-gray-700">
        <ZoomIn>
          {(props: any) => (
            <button className="rounded-full px-2 py-1 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
            </button>
          )}
        </ZoomIn>
        <ZoomOut>
          {(props: any) => (
            <button className="rounded-full px-2 py-1 hover:bg-gray-200" onClick={props.onClick}>
              <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
            </button>
          )}
        </ZoomOut>
      </div>
    </>
  );
};

export { FloatingControls, HeaderControls };
