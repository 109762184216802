import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';

interface PreSaveCaseData {
  caseName: string;
  caseIssues: string;
  caseType: string;
  caseParties: string;
  caseKeyContext?: string;
  caseKeyTimePeriod?: string;
  disputeStatus?: string;
}

export const useSaveCase = (caseId: string) => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  const saveCase = async (data: PreSaveCaseData) => {
    const fetchConfig = getFetchConfig({
      method: 'PUT',
      data,
    });
    const response = await fetch(`${APIBaseChronos}/client/case/presave/${caseId}`, fetchConfig);
    return response.json();
  };

  return useMutation({
    mutationFn: saveCase,
    onSuccess: () => {
      queryClient.invalidateQueries(['caseMetadata', caseId]);
    },
  });
};
