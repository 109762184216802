import { faCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConfidenceLevelObject } from '../../../types';

interface ConfidenceChipProps {
  confidenceLevel: ConfidenceLevelObject;
  onClick: () => void;
}

const ConfidenceChip = ({ confidenceLevel, onClick }: ConfidenceChipProps) => {
  return (
    <div
      onClick={onClick}
      className={`relative hover:cursor-pointer ${confidenceLevel.backgroundColor} rounded border px-2 py-1 text-xs shadow ${confidenceLevel.borderColor} flex items-center gap-2 border`}
    >
      <p>
        Confidence: <b>{confidenceLevel.text}</b>
      </p>
      <FontAwesomeIcon icon={faCircle} className={`${confidenceLevel.textColor}`} />
      <FontAwesomeIcon
        icon={faInfoCircle}
        className="absolute right-[-16px] top-[-8px] ml-2 mr-2 cursor-pointer rounded-full text-sm text-blue-500 hover:text-blue-800"
      />
    </div>
  );
};

export default ConfidenceChip;
