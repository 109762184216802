import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export const useGetMatter = ({ matterId }: { matterId: string | null }) => {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery([QueryEntity.Matter, { matterId }], async () => {
    return fetch(`${APIBaseChronos}/client/case/matter/${matterId}`, fetchConfigGET)
      .then((res) => res.json())
      .catch((err) => {});
  });
};
