import { useEffect, useRef } from 'react';

import { EventSourcePolyfill } from 'event-source-polyfill';

export const useEventSource = () => {
  const eventSourceRef = useRef<EventSourcePolyfill | null>(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      console.log('Running cleanup');
      isMounted.current = false;
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const setEventSourceRef = (eventSource: EventSourcePolyfill | null) => {
    eventSourceRef.current = eventSource;
  };

  return {
    eventSourceRef: eventSourceRef.current,
    setEventSourceRef,
    isMounted,
  };
};
