import { useQuery } from 'react-query';

import { APIBaseChronos } from '../hosts';
import { QueryEntity } from '../types';
import { FetchEventCoordinatesByDocRes } from '../types/queries';
import useGetFetchConfig from '../useGetFetchConfig';

const useFetchEventCoordinatesByDoc = ({ docId }: { docId: string }) => {
  const { fetchConfigGET } = useGetFetchConfig();

  const getRefCoords = () => {
    return fetch(`${APIBaseChronos}/client/case/fact/coordinatesByDocId/${docId}`, fetchConfigGET).then((res) => {
      return res.json();
    });
  };

  return useQuery<FetchEventCoordinatesByDocRes>({
    queryKey: [QueryEntity.CaseEventCoordinates, { docId }],
    queryFn: getRefCoords,
  });
};

export default useFetchEventCoordinatesByDoc;
