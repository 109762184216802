import moment from 'moment';

interface ViewCellProps {
  date: Date;
  format?: string;
}

const DateCell = ({ date }: ViewCellProps) => {
  return (
    <div className="flex w-56 flex-col items-start justify-center py-5 not-italic">
      {date && (
        <div className="ml-auto flex w-full flex-row items-center justify-start">
          {moment.tz(date, 'Europe/London').format('Do MMMM HH:mm A')}
        </div>
      )}
    </div>
  );
};

export default DateCell;
