import { useCallback, useEffect, useRef, useState } from 'react';

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  highlightPlugin,
  RenderHighlightContentProps,
  RenderHighlightsProps,
  RenderHighlightTargetProps,
  Trigger,
} from '@react-pdf-viewer/highlight';
import { trackPageView } from 'analytics/Mixpanel';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { APIBaseChronos } from 'api/hosts';
import useFetchEventCoordinatesByDoc from 'api/queries/useFetchEventCoordinatesByDoc';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import ToastDrawer from 'components/molecules/ToastDrawer';
import PDFViewer from 'components/organisms/PDFViewer';
import { motion } from 'framer-motion';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { ChronosFact } from 'types';
import { ChronosDocumentCoordinate, Coordinate, HighlightCoordinate } from 'types';

import useFetchDocumentFacts from '../../../DataView/DocumentsEditor/hooks/useFetchDocumentFacts';
import FactAdderModal from '../../Modals/FactAdderModal';
import DocViewerFactSidebar from '../components/DocViewerFactSidebar';
import DocViewerFullFact from '../components/DocViewerFullFact';

export interface FactPosition {
  pageIndex: number;
  top: number;
  coordinates: HighlightCoordinate;
  index?: number;
}

const DocViewer = ({
  caseId,
  docId,
  pageNumber,
  factTotal,
  title,
}: {
  caseId: string;
  docId: string;
  pageNumber: number;
  factTotal: string | null;
  title?: string;
}) => {
  // State
  const navigate = useNavigate();
  const location = useLocation();

  const viewerRef = useRef(null);

  // State for managing virtualised list
  const listRef = useRef<List>(null);

  const [facts, setFacts] = useState<Array<ChronosFact | { event_id: string }>>([]);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [loadingFile, setLoadingFile] = useState(true);
  const [minPage, setMinPage] = useState(1);
  const [highlightCoordinates, setHighlightCoordinates] = useState<HighlightCoordinate[]>([]);
  const [highlightedFactId, setHighlightedFactId] = useState<string | null>(null);
  const [selectedFactId, setSelectedFactId] = useState<string | null>(null);
  const [factPositions, setFactPositions] = useState<Map<string, FactPosition>>(new Map());
  const [selectedFact, setSelectedFact] = useState<ChronosFact>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [dataDirty, setDataDirty] = useState(false);

  const [factAdderModalIsOpen, setFactAdderModalIsOpen] = useState(false);

  const { fetchConfigGET } = useGetFetchConfig();

  // Data fetching -------------
  const getDocumentUrl = useCallback(async () => {
    if (!docId) return null; // Early exit if no docId provided

    try {
      const downloadResult = await fetch(`${APIBaseChronos}/client/case/doc/download/${docId}`, fetchConfigGET);
      if (!downloadResult.ok) throw new Error('Download failed');

      const blob = await downloadResult.blob();
      const fileBlobUrl = URL.createObjectURL(blob);
      // Consider where and how to call URL.revokeObjectURL(fileBlobUrl) for cleanup
      return fileBlobUrl;
    } catch (error) {
      console.error(error);
      return null; // Consider a structured error handling approach
    }
  }, [docId, fetchConfigGET]); // Add all dependencies here

  const { refetch: fetchDocument } = useQuery(['downloadDoc', docId], getDocumentUrl, { enabled: false });

  const {
    data: responseFacts,
    isLoading,
    isFetching,
    refetch: refetchCaseFacts,
  } = useFetchDocumentFacts({ docId, caseId });

  const { data: responseCoordinates } = useFetchEventCoordinatesByDoc({ docId });
  //------------------------------------------------------

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedFactId(null);
  };

  useEffect(() => {
    if (selectedFactId && responseFacts) {
      const factIndex = factPositions.get(selectedFactId)?.index;
      if (factIndex === undefined) return;
      const newSelectedFact = facts[factIndex];

      // If there is already a selectect fact and drawer is open, close the drawer wait 1s and open it again with the new fact
      if (selectedFact && isDrawerOpen) {
        setIsDrawerOpen(false);
        setTimeout(() => {
          setSelectedFact(newSelectedFact as ChronosFact);
          setIsDrawerOpen(true);
        }, 500);
      } else {
        setSelectedFact(newSelectedFact as ChronosFact);
        setIsDrawerOpen(true);
      }
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedFactId, responseFacts, factPositions, isDrawerOpen, selectedFact, facts]);

  useEffect(() => {
    if (docId) {
      setLoadingFile(true);
      fetchDocument().then(({ data: documentUrl }) => {
        if (documentUrl && typeof documentUrl === 'string') {
          setFileUrl(documentUrl);
        }
        setLoadingFile(false);
      });
    }
    // eslint-disable-next-line
  }, [docId]);

  // Coordinate processing
  useEffect(() => {
    if (responseCoordinates && docId) {
      setMinPage(responseCoordinates.minPage);

      const coordinates =
        responseCoordinates?.factsCoordinates
          ?.filter((coordinate: ChronosDocumentCoordinate) => coordinate.doc_id === docId)
          ?.flatMap((coordinate: ChronosDocumentCoordinate) =>
            coordinate.coordinate_details.map((coordinate_detail: Coordinate) => ({
              eventId: coordinate.event_id,
              height: coordinate_detail.height_c * 100,
              left: coordinate_detail.left_c * 100,
              pageIndex: coordinate_detail.n_page - 1,
              top: coordinate_detail.top_c * 100,
              width: coordinate_detail.width_c * 100,
            })),
          ) || [];
      setHighlightCoordinates(coordinates);
    }
  }, [responseCoordinates, docId]);

  // Create fact -> coordinate mapping
  useEffect(() => {
    if (highlightCoordinates.length > 0) {
      const factPositions = new Map<string, FactPosition>();

      for (const coordinate of highlightCoordinates) {
        if (
          !factPositions.has(coordinate.eventId) ||
          factPositions.get(coordinate.eventId)!.pageIndex > coordinate.pageIndex
        ) {
          factPositions.set(coordinate.eventId, {
            pageIndex: coordinate.pageIndex,
            top: coordinate.top,
            coordinates: coordinate,
          });
        }
      }

      setFactPositions(factPositions);
    }
  }, [highlightCoordinates]);

  // Processing on facts
  useEffect(() => {
    if (responseFacts && docId) {
      const facts = [];
      let markerAdded = false;
      for (const [index, fact] of responseFacts.facts.entries()) {
        if (factPositions.has(fact.event_id)) {
          factPositions.get(fact.event_id)!.index = index;
          facts.push(fact);
        } else {
          if (!markerAdded) {
            facts.push({ event_id: 'marker' });
            markerAdded = true;
          }
          facts.push(fact);
        }
      }
      setFacts(facts);
    }
  }, [responseFacts, docId, factPositions]);

  // Event handlers
  const handleCloseDocDetailView = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('docId');
    searchParams.delete('docPageNumber');
    navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
  }, [navigate, location.pathname, location.search]);

  const handleNewFactCreated = useCallback(() => {
    setFactAdderModalIsOpen(false);
    trackCustomEvent('New Fact Created From Doc Viewer');
    // eslint-disable-next-line
  }, []);

  // Highlight Handlers
  const scrollToFact = (factId: string) => {
    const factPosition = factPositions.get(factId);
    if (factPosition && factPosition.index && listRef.current) {
      listRef.current.scrollToItem(factPosition.index, 'center');
    }
  };

  const renderHighlights = (props: RenderHighlightsProps) => {
    return (
      <div>
        {highlightCoordinates
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className={`highlight-area ${
                area.eventId === highlightedFactId ? 'bg-brandSecondary' : 'bg-yellow-300'
              } z-10 cursor-pointer rounded-sm`}
              style={Object.assign({}, props.getCssProperties(area, props.rotation), {
                pointerEvents: 'auto',
                opacity: '0.3',
                mixBlendMode: 'darken',
              })}
              onClick={(e) => {
                e.stopPropagation();
                trackCustomEvent('Click Jump to Fact From PDF Viewer Highlight');
                setHighlightedFactId(area.eventId);
                scrollToFact(area.eventId);
              }}
            ></div>
          ))}
      </div>
    );
  };

  const renderHighlightTarget = (props: RenderHighlightTargetProps) => (
    <div
      style={{
        background: '#eee',
        display: 'flex',
        position: 'absolute',
        left: `${props.selectionRegion.left}%`,
        top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
        transform: 'translate(0, 8px)',
        zIndex: 10,
      }}
    >
      <Button
        icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
        className="rounded border bg-buttonSecondary px-2 py-2 text-xs shadow hover:bg-buttonSecondary-hover"
        onClick={props.toggle}
        text="Add Fact"
      />
    </div>
  );

  const renderHighlightContent = (props: RenderHighlightContentProps) => {
    const onSuccess = () => {
      trackCustomEvent('New Fact Created From Doc Viewer');
      props.cancel();
    };

    return (
      <FactAdderModal
        docId={docId}
        onSuccess={onSuccess}
        selectedText={props.selectedText}
        onClose={props.cancel}
        highlightAreas={props.highlightAreas}
        isOpen
      />
    );
  };

  const highlightPluginAddFact = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    trigger: Trigger.TextSelection,
  });

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });
  const { jumpToHighlightArea } = highlightPluginInstance;

  const handleFactUpdated = () => {
    setDataDirty(true);
  };

  trackPageView('Doc viewer');

  return (
    <div className="flex h-full">
      <div className="w-7/12 overflow-hidden">
        {fileUrl === 'download-error' ? (
          'File cannot be displayed, please try again later.'
        ) : (
          <div ref={viewerRef} className="h-full">
            <PDFViewer
              fileUrl={fileUrl}
              loading={loadingFile}
              highlightInstance={highlightPluginInstance}
              highlightToAddFactInstance={highlightPluginAddFact}
              initialPage={pageNumber || minPage}
              handleClosePDFViewer={handleCloseDocDetailView}
              closeButtonText="Back"
              defaultZoom={1.1}
              type="full"
              title={title}
            />
          </div>
        )}
      </div>
      <DocViewerFactSidebar
        docId={docId}
        isLoading={isLoading || isFetching}
        factPositions={factPositions}
        dataDirty={dataDirty}
        setDataDirty={setDataDirty}
        factTotal={factTotal}
        jumpToHighlightArea={jumpToHighlightArea}
        highlightedFactId={highlightedFactId}
        setHighlightedFactId={setHighlightedFactId}
        setSelectedFactId={setSelectedFactId}
        facts={facts}
        listRef={listRef}
        setFactAdderModalIsOpen={setFactAdderModalIsOpen}
        refetchCaseFacts={refetchCaseFacts}
      />
      <ToastDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
        {selectedFact && (
          <motion.div key={selectedFact.event_id}>
            <DocViewerFullFact fact={selectedFact} handleFactUpdated={handleFactUpdated} />
          </motion.div>
        )}
      </ToastDrawer>

      <FactAdderModal
        docId={docId}
        onSuccess={handleNewFactCreated}
        onClose={() => setFactAdderModalIsOpen(false)}
        isOpen={factAdderModalIsOpen}
      />
    </div>
  );
};

export default DocViewer;
