import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import TooltipIcon from 'components/atoms/TooltipIcon';
import SearchBox from 'components/molecules/SearchBox';
import Pagination from 'components/organisms/Pagination';
import isPlural from 'helpers/isPlural';

import DataViewToolbar from '../../components/TableToolbar';

interface DocumentEditorToolbarProps {
  processedCount: number;
  totalDocs: number;
  openSettingsModal: () => void;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  onSearchCall: () => void;
  clearSearch: () => void;
  currentPage: number;
  totalPages: number;
  prevPage: () => void;
  nextPage: () => void;
  goToPage: (page: number) => void;
}

const DocumentEditorToolbar = ({
  processedCount,
  totalDocs,
  openSettingsModal,
  globalFilter,
  setGlobalFilter,
  onSearchCall,
  clearSearch,
  currentPage,
  totalPages,
  prevPage,
  nextPage,
  goToPage,
}: DocumentEditorToolbarProps) => {
  return (
    <DataViewToolbar
      titleSection={
        <>
          <h1 className="text-lg font-semibold">Documents </h1>
          <div className="flex w-fit items-center gap-1 rounded border border-brandSecondary border-opacity-60 bg-gray-50 px-2 py-0.5 text-xs font-normal">
            <span>
              <b>
                {processedCount}/{totalDocs}
              </b>{' '}
              processed
            </span>
            {processedCount !== totalDocs && (
              <TooltipIcon
                className="h-3 w-3"
                tooltipId="add-facts-tooltip"
                tooltipContent="Add facts manually from unprocessed documents"
              />
            )}
          </div>
        </>
      }
      actionButtons={
        <Button
          onClick={openSettingsModal}
          className="rounded border bg-buttonSecondary px-2 py-2 text-xs shadow hover:bg-buttonSecondary-hover"
          text="Add Documents"
          icon={<FontAwesomeIcon icon={faPlusCircle} className="pr-2" />}
        />
      }
      searchBar={
        <SearchBox
          value={globalFilter}
          placeholder={`Search ${totalDocs} ${isPlural({ one: 'document', more: 'documents', number: totalDocs })}`}
          onChange={setGlobalFilter}
          onSearchCall={onSearchCall}
          clearSearch={clearSearch}
        />
      }
      pagination={
        <Pagination
          canGetPrevPage={currentPage > 0}
          canGetNextPage={currentPage < totalPages - 1}
          prevPage={prevPage}
          nextPage={nextPage}
          currentPage={currentPage}
          noOfPages={totalPages}
          goToPage={goToPage}
        />
      }
    />
  );
};

export default DocumentEditorToolbar;
