import React, { useCallback, useEffect, useRef } from 'react';

import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SearchBoxProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onSearchCall?: () => void;
  loading?: boolean;
  clearSearch?: () => void;
  debounceTime?: number; // Add optional debounce time prop
}

const SearchBox: React.FC<SearchBoxProps> = ({
  value,
  onChange,
  placeholder,
  onSearchCall,
  clearSearch,
  debounceTime = 500, // Default debounce of 500ms
}) => {
  const lastSearchedValue = useRef(value);

  // Debounced search handler
  const debouncedSearch = useCallback(() => {
    if (onSearchCall && value.trim() && value !== lastSearchedValue.current) {
      lastSearchedValue.current = value;
      onSearchCall();
    }
  }, [onSearchCall, value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      debouncedSearch();
    }, debounceTime);

    return () => clearTimeout(timer);
  }, [value, debouncedSearch, debounceTime]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSearchCall && value.trim()) {
      onSearchCall();
    }
  };

  return (
    <div className="relative flex flex-row items-center justify-center rounded-md border border-gray-300 bg-white pl-2 text-sm xl:w-72">
      <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
      <input
        className="h-8 w-full rounded-md bg-white pl-4 font-normal not-italic text-black placeholder:text-gray-400 focus:outline-none"
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || 'Search cases'}
        onKeyDown={onKeyDown}
      />
      {clearSearch && (
        <button
          className={`mr-2 cursor-pointer text-gray-600 transition-opacity duration-500 ${
            value ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={clearSearch}
        >
          <FontAwesomeIcon icon={faX} className="h-3 w-3" />
        </button>
      )}
    </div>
  );
};

export default React.memo(SearchBox);
