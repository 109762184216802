import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

import { ThreadObject } from '../types';

const useGetThreads = (caseId: string) => {
  const { fetchConfigGET } = useGetFetchConfig();
  const getThreads = async () => {
    if (!caseId) {
      return [];
    }

    const response = await fetch(`${APIBaseChronos}/client/case/assistant/threads/${caseId}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching threads failed');
    }
    return response.json();
  };

  const {
    isFetching,
    data = { threads: [] },
    error,
  } = useQuery<{ threads: ThreadObject[] }>(['threads', caseId], getThreads, {
    refetchOnWindowFocus: false,
  });

  return {
    isFetching,
    threads: data.threads.reverse(),
    error,
  };
};

export default useGetThreads;
