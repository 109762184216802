import { faCircleNotch, faMagnifyingGlass, faPlus, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trackCustomEvent } from 'analytics/Mixpanel';
import { useNavigate } from 'react-router-dom';
import ActionButton from 'screens/Chronos/CaseEditor/CaseSummary/components/ActionButton';
import { useCreateCase } from 'screens/Chronos/CasesExplorer/hooks/useCreateCase';
import { UserAction } from 'types';

import useGetUserActions from '../../hooks/useGetUserActions';

const Action = ({ action }: { action: UserAction }) => {
  const navigate = useNavigate();
  const { mutate: createCase } = useCreateCase();

  const iconClass = 'text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full';
  const animationClasses = 'animate-fadeIn';

  switch (action.action) {
    case 'ask-kim':
      return (
        <div className={animationClasses}>
          <ActionButton
            title="Kim Question"
            description={`Ask Kim a question in ${action.caseName}`}
            onClick={() => {
              trackCustomEvent('Click Ask Kim from homepage');
              navigate(`/app/chronos/case-editor/assistant?caseId=${action.caseId}`);
            }}
            icon={<FontAwesomeIcon icon={faQuestion} className={iconClass} />}
          />
        </div>
      );
    case 'explore-facts':
      return (
        <div className={animationClasses}>
          <ActionButton
            title="Explore Facts"
            description={`Explore facts in ${action.caseName}`}
            onClick={() => {
              trackCustomEvent('Click Explore Facts from homepage');
              navigate(`/app/chronos/case-editor/data/facts?caseId=${action.caseId}`);
            }}
            icon={<FontAwesomeIcon icon={faMagnifyingGlass} className={iconClass} />}
          />
        </div>
      );
    case 'create-case':
      return (
        <div className={animationClasses}>
          <ActionButton
            title="Create Case"
            description={`Create a new case in ${action.matterName}`}
            onClick={() => {
              if (action.matterId) {
                trackCustomEvent('Click Create Case from homepage');
                createCase(action.matterId);
              }
            }}
            icon={<FontAwesomeIcon icon={faPlus} className={iconClass} />}
          />
        </div>
      );
  }
};

const Actions = ({ userId }: { userId: string }) => {
  const { data: userActions, isLoading } = useGetUserActions(userId);

  return (
    <div className="mb-6">
      <h2 className="mb-2 border-2 border-x-0 border-t-0 px-4 pb-1 text-lg font-semibold">Where you left off...</h2>
      <div className="flex w-full flex-col gap-4 px-4 py-2 text-sm text-gray-800">
        {isLoading ? (
          <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
        ) : (
          userActions?.userActions.map((action) => <Action key={action.action} action={action} />)
        )}
      </div>
    </div>
  );
};

export default Actions;
