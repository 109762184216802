import { APIBaseChronos } from 'api/hosts';
import { QueryEntity } from 'api/types';
import { FetchFactsByDocRes } from 'api/types/queries';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation, useQueryClient } from 'react-query';

const BATCH_SIZE = 500;

const useToggleAll = () => {
  const { getFetchConfig } = useGetFetchConfig();
  const queryClient = useQueryClient();

  // Define the mutation
  return useMutation({
    onMutate: async ({ toggleValue, caseId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryEntity.CaseEvent, { caseId }] });

      // Snapshot the previous value
      const previousFactsRes = queryClient.getQueryData<FetchFactsByDocRes>([QueryEntity.CaseEvent, { caseId }]);

      // Optimistically update to the new value
      queryClient.setQueryData<FetchFactsByDocRes | undefined>([QueryEntity.CaseEvent, { caseId }], (old) => {
        if (old) {
          return {
            ...old,
            includedFactsCount: toggleValue ? old.totalFactsCount : 0,
            facts: old.facts.map((fact) => ({
              ...fact,
              included: toggleValue,
            })),
          };
        }
      });

      // Return a context object with the snapshotted value
      return { previousFactsRes };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { caseId }, context) => {
      queryClient.setQueryData([QueryEntity.CaseEvent, { caseId }], context?.previousFactsRes);
    },
    // Always refetch after error or success:
    onSettled: ({ caseId }) => {
      queryClient.invalidateQueries({ queryKey: [QueryEntity.CaseEvent, { caseId }] });
    },
    mutationFn: async ({
      caseId,
      toggleValue,
      filterParams,
    }: {
      caseId: string;
      toggleValue: boolean;
      filterParams: { [key: string]: string[] };
    }) => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
      });

      // Send the event ids in batches
      const sendBatch = async (batchFilterParams: { [key: string]: string[] }) => {
        const batchFetchConfig = {
          ...fetchConfig,
          body: JSON.stringify({ included: toggleValue, caseId, filters: batchFilterParams }),
        };

        const response = await fetch(`${APIBaseChronos}/client/case/fact/toggleAllIncluded`, batchFetchConfig);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      };

      if (filterParams.event_id && filterParams.event_id.length > BATCH_SIZE) {
        const batches = [];
        for (let i = 0; i < filterParams.event_id.length; i += BATCH_SIZE) {
          const batchFilterParams = { ...filterParams, event_id: filterParams.event_id.slice(i, i + BATCH_SIZE) };
          batches.push(sendBatch(batchFilterParams));
        }
        return Promise.all(batches);
      } else {
        return sendBatch(filterParams);
      }
    },
  });
};

export default useToggleAll;
