import React from 'react';

import { Header, flexRender } from '@tanstack/react-table';
import { ChronosDoc } from 'types'; // Ensure these types are correctly imported based on your project's structure

interface HeaderContentProps {
  header: Header<ChronosDoc, unknown>;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({ header }) => {
  return (
    <div className={`flex cursor-pointer flex-row items-center justify-start pl-3`}>
      <div className={`flex flex-row items-center justify-start`}>
        {flexRender(header.column.columnDef.header, header.getContext())}
      </div>
    </div>
  );
};
