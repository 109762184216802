import { ParameterValue, PlanParameters, PlanStage } from 'types';

import { PlanDrawer, PlanPage } from './components/Plan';

interface ProcessingProps {
  planStage: Record<string, string>;
  plan: PlanStage[];
  processedQuestion: string;
  planOpen: boolean;
  loadingPlan: boolean;
  planEditable: boolean;
  loading: boolean;
  messages: string[];
  cancelPlan: () => void;
  setPlan: (plan: PlanStage[]) => void;
  executePlan: (plan: PlanStage[]) => void;
  handleBack: () => void;
}

const Processing = ({
  planStage,
  plan,
  planOpen,
  loadingPlan,
  planEditable,
  setPlan,
  loading,
  messages,
  processedQuestion,
  cancelPlan,
  executePlan,
  handleBack,
}: ProcessingProps) => {
  const updatePlan = (plan: PlanStage[], step: number, parameterName: string, newValue: ParameterValue) => {
    // Get the plan step
    const planStep = plan[step];

    // Find the parameter
    const parameter = planStep.parameters.find((p) => p.name === parameterName);
    const index = planStep.parameters.findIndex((p) => p.name === parameterName);

    // Set the parameter value to the new value
    if (!parameter) {
      return;
    }
    const updatedParameter = { ...parameter, value: newValue, updated: true };

    // Update the plan
    planStep.parameters[index] = updatedParameter as PlanParameters;
    const newPlan = [...plan];
    newPlan[step] = planStep;

    setPlan(newPlan);
  };

  return (
    <div className="relative flex h-full w-full">
      <PlanDrawer
        planStage={planStage}
        plan={plan}
        planOpen={planOpen}
        loadingPlan={loadingPlan}
        executePlan={executePlan}
        executing={loading}
        editable={planEditable}
        updatePlan={updatePlan}
        cancelPlan={cancelPlan}
        handleBack={handleBack}
      />
      <PlanPage questionValue={processedQuestion} loading={loading} messages={messages} />
    </div>
  );
};

export default Processing;
