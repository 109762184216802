import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

import { DraftType, KimMode } from '../types';

interface UserCreateMessageProps {
  caseId: string;
  question: string;
  mode: KimMode;
  docIds?: string[];
  draftType?: DraftType | null;
  files?: File[];
  threadId: string;
}

const useCreateMessage = () => {
  const { getFetchConfig } = useGetFetchConfig();

  const createMessage = async ({
    threadId,
    question,
    caseId,
    docIds,
    draftType = null,
    mode,
    files = [],
  }: UserCreateMessageProps) => {
    let data;

    if (files.length) {
      let formData = new FormData();

      for (const file of files) {
        formData.append('files[]', file);
      }

      for (const docId of docIds || []) {
        formData.append('docIds[]', docId);
      }

      formData.append('question', question);
      formData.append('mode', mode);
      if (draftType) {
        formData.append('draftType', draftType);
      }

      data = formData;
    } else {
      data = {
        question,
        docIds,
        mode,
        draftType,
        threadId,
      };
    }

    const fetchConfig = getFetchConfig({
      isFormData: files.length > 0,
      method: 'POST',
      data,
    });

    const response = await fetch(`${APIBaseChronos}/client/case/assistant/question/${caseId}`, fetchConfig);
    if (!response.ok) {
      throw new Error('Error creating message');
    }
    return response.json();
  };

  const mutation = useMutation(
    async ({ question, caseId, docIds, draftType, mode, files, threadId }: UserCreateMessageProps) => {
      return createMessage({ question, caseId, docIds, mode, draftType, files, threadId });
    },
  );

  return mutation;
};

export default useCreateMessage;
