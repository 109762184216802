import { useState } from 'react';

import { faChevronCircleLeft, faFile, faMagnifyingGlass, faTimeline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import ChronologyViewer from './ChronologyViewer';
import DocumentsEditor from './DocumentsEditor';
import FactsEditor from './FactsEditor';

const DataView = ({ openSettingsModal }: { openSettingsModal: () => void }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const caseId = searchParams.get('caseId');

  const [isCollapsed, setIsCollapsed] = useState(false); // Sidebar state

  const handleTabClick = (e: React.MouseEvent, path: string) => {
    navigate(path);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const NavItem = ({ title, route, icon }: { title: string; route: string; icon: React.ReactNode }) => {
    const isActive = location.pathname === route.split('?')[0];

    return (
      <div
        className={`flex items-center gap-2 px-3 py-2 text-sm hover:cursor-pointer hover:bg-gray-200 ${
          isActive ? 'bg-gray-200' : ''
        }`}
        onClick={(e) => handleTabClick(e, route)}
      >
        {icon}
        {title}
      </div>
    );
  };

  return (
    <div className="flex h-[87%] w-full">
      {/* Sidebar container */}
      <div
        className={`transition-width relative flex h-full flex-col rounded border-2 bg-white py-1 duration-500 ease-in-out ${
          isCollapsed ? 'w-0 border-gray-50 border-opacity-100' : 'mr-2 w-36 border-gray-200 border-opacity-80'
        } `} // Dynamically set width and transition
      >
        <div className={`absolute top-[-6px] ${isCollapsed ? 'left-[-8px]' : 'right-[-8px]'}`}>
          <FontAwesomeIcon
            icon={faChevronCircleLeft} // Change icon based on state
            className={`h-5 w-5 cursor-pointer text-brandSecondary ${isCollapsed ? 'rotate-180' : ''}`}
            onClick={toggleSidebar}
          />
        </div>

        {!isCollapsed && ( // Render sidebar content only when it's expanded
          <>
            <div className="mt-2 border-b px-4 pb-1 text-sm text-gray-600">Data</div>
            <NavItem
              title="Facts"
              route={`/app/chronos/case-editor/data/facts?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} className="h-3 w-3 text-gray-700" />}
            />
            <NavItem
              title="Documents"
              route={`/app/chronos/case-editor/data/documents?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faFile} className="h-3 w-3 text-gray-700" />}
            />
            <div className="mt-8 border-b px-4 pb-1 text-sm text-gray-500">Outputs</div>
            <NavItem
              title="Chronologies"
              route={`/app/chronos/case-editor/data/chronology?caseId=${caseId}`}
              icon={<FontAwesomeIcon icon={faTimeline} className="h-3 w-3 text-gray-700" />}
            />
          </>
        )}
      </div>

      {/* Main content area */}
      <div className="h-full w-full overflow-x-auto overflow-y-hidden rounded border-2 border-gray-200 border-opacity-80 bg-white px-4 transition-all duration-300">
        <Routes>
          <Route path="facts" element={<FactsEditor />} />
          <Route path="documents" element={<DocumentsEditor openSettingsModal={openSettingsModal} />} />
          <Route path="chronology/*" element={<ChronologyViewer />} />
        </Routes>
      </div>
    </div>
  );
};

export default DataView;
