import { faChartSimple, faFile, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';

import RelevantDocs from '../RelevantDocs';
import StatsTable from '../StatsTable';

const OverviewSection = ({
  handleViewKeyFacts,
  isLoading,
  overview,
}: {
  handleViewKeyFacts: VoidFunction;
  isLoading: boolean;
  overview: any; // TODO: type CaseOverview
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const handleViewFacts = () => {
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/facts?' + searchParams.toString());
  };

  const handleViewDocs = () => {
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/documents?' + searchParams.toString());
  };

  const handleRelevantDocClick = (docId: string) => {
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('docId', docId);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/data/documents?' + searchParams.toString());
  };

  return (
    <div className="white relative w-3/4 rounded-lg border-2 border-gray-200 border-opacity-60 bg-white p-4 py-4">
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <StageSpinner color="#013E45" size={28} />
        </div>
      ) : (
        <>
          <h2 className="mb-4 border-2 border-x-0 border-t-0 pb-1 font-semibold">Overview</h2>
          <div className="flex w-full gap-4 px-1 text-sm text-gray-600">
            <div className="flex w-1/3 flex-col gap-4 border-2 border-y-0 border-l-0 pr-10">
              <p className="border border-x-0 border-t-0 text-base font-medium">
                <FontAwesomeIcon icon={faChartSimple} className="mr-2 h-4 w-4 text-gray-800" />
                Totals
              </p>
              <StatsTable
                stats={{
                  factsExtracted: overview?.caseOverview?.totalFacts || 0,
                  relevantFacts: overview?.caseOverview?.relevantFacts || 0,
                  keyFacts: overview?.caseOverview?.keyFacts || 0,
                  documents: overview?.caseOverview?.documents || 0,
                  pages: overview?.caseOverview?.pages || 0,
                }}
                onClickHandlers={{
                  factsExtracted: handleViewFacts,
                  relevantFacts: handleViewFacts,
                  keyFacts: handleViewKeyFacts,
                  documents: handleViewDocs,
                  pages: handleViewDocs,
                }}
                tooltips={{
                  factsExtracted: 'Total number of facts extracted from the documents.',
                  relevantFacts: 'Number of facts deemed relevant to the key case issues.',
                  keyFacts: 'Number of key facts extracted from the documents.',
                  documents: 'Total number of documents in the case.',
                  pages: 'Total number of pages across the documents in the case.',
                }}
              />
            </div>

            <div className="flex w-2/3 flex-col gap-4 pl-4 text-sm">
              <p className="flex items-center border border-x-0 border-t-0 text-base font-medium">
                <FontAwesomeIcon icon={faFile} className="mr-2 h-4 w-4 text-gray-800" />
                Most Relevant Documents
                <FontAwesomeIcon
                  data-tooltip-id="relevant-docs"
                  data-tooltip-content="The top 3 most relevant documents based on the number of relevant facts extracted from them."
                  icon={faInfoCircle}
                  className="ml-2 h-3 w-3 cursor-pointer text-gray-400"
                />
                <Tooltip
                  id="relevant-docs"
                  opacity={1}
                  style={{
                    width: '400px',
                    textAlign: 'center',
                    padding: '8px',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    color: 'black',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '-0.3px',
                    zIndex: 1,
                  }}
                />
              </p>
              <RelevantDocs docs={overview?.caseOverview?.docEvents} onClick={handleRelevantDocClick} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default OverviewSection;
