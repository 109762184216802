import { useRef, useState } from 'react';

import { faCheck, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';
import { twMerge } from 'tailwind-merge';

interface EditableFieldProps {
  isLoading: boolean;
  value: string;
  onSave: (value: string) => void;
  textClassName?: string;
  onFieldClick?: VoidFunction;
}

const EditableField = ({
  value: initialValue,
  onSave,
  textClassName = 'font-semibold text-xl',
  isLoading,
  onFieldClick,
}: EditableFieldProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(initialValue);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '28px'; // Reset the height to its initial value
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const togleEditMode = () => {
    if (!isEdit) {
      setIsEdit(true);
      setTimeout(() => {
        autoResize(textAreaRef);
      }, 0);
    } else {
      setIsEdit(false);
      onSave(value);
    }
  };

  const handleChangeFieldValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    autoResize(textAreaRef);
  };

  if (isLoading) {
    return <StageSpinner className="m-auto" size={28} color={'#4161FF'} />;
  }

  if (isEdit) {
    return (
      <div className="flex w-full flex-row items-center">
        <textarea
          ref={textAreaRef}
          className={twMerge('w-full resize-none overflow-hidden rounded-md pl-2', textClassName)}
          onChange={handleChangeFieldValue}
          value={value}
          style={{
            outline: 'none',
            border: '1px solid rgba(0,0,0,0.1)',
            minWidth: '100px',
            width: '100%',
            height: '28px',
          }}
        />
        <FontAwesomeIcon onClick={togleEditMode} icon={faCheck} className="ml-2 cursor-pointer text-gray-700" />
      </div>
    );
  }

  return (
    <div className="mb-2 flex items-center">
      <p className={twMerge(textClassName, onFieldClick && 'cursor-pointer')} onClick={onFieldClick}>
        {value}
      </p>
      <FontAwesomeIcon
        onClick={togleEditMode}
        icon={faPencil}
        className="ml-2 cursor-pointer text-gray-700"
        aria-label="edit"
      />
    </div>
  );
};

export default EditableField;
