import { useState, useEffect, useRef } from 'react';

import { faPlus, faXmark, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlanParameters, Option, ParameterValue } from 'types';

const StringParameter = ({
  parameterName,
  parameterValue,
  updatePlan,
}: {
  parameterName: string;
  parameterValue: ParameterValue | null;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [parameterValue]);

  return (
    <div className="flex flex-col py-1">
      <label className="font-semibold" htmlFor={parameterName}>
        {parameterName}:
      </label>
      <textarea
        ref={textareaRef}
        id={parameterName}
        className="min-h-[20px] resize-none rounded border px-1 py-1"
        value={parameterValue as string}
        onChange={(e) => {
          updatePlan(parameterName, e.target.value);
        }}
      />
    </div>
  );
};

// NOT IMPLEMENTED
const BooleanParameter = ({
  parameterName,
}: {
  parameterName: string;
  parameterValue: ParameterValue | null;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  return (
    <label key={parameterName} className="relative inline-flex cursor-pointer items-center">
      <input id="switch" type="checkbox" className="peer sr-only" />
      <label htmlFor="switch" className="hidden"></label>
      <div className="peer h-4 w-7 rounded-full border bg-slate-200 after:absolute after:left-[2px] after:top-0.5 after:h-3 after:w-3 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-slate-800 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
    </label>
  );
};

const SingleSelectListParameter = ({
  parameterName,
  parameter,
  parameterValue,
  updatePlan,
}: {
  parameterName: string;
  parameter: PlanParameters & { available_values: Option[] };
  parameterValue: Option[];
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  return (
    <select
      className="inline-block w-auto cursor-pointer rounded border border-blue-200 bg-blue-100 py-0 hover:bg-blue-200"
      value={parameterValue[0].value}
      // This might be wrong
      onChange={(e) => updatePlan(parameterName, [{ value: e.target.value, label: e.target.value }])}
    >
      {parameter.available_values.map((option: Option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

// NOT IMPLEMENTED
const MultiSelectListParameter = ({
  parameter,
}: {
  parameterName: string;
  parameter: PlanParameters & { all_selection: boolean; value: Option[]; available_values: Option[] };
  parameterValue: ParameterValue;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  const allSelection = parameter.all_selection;
  if (allSelection && parameter.value.length === 0) {
    return (
      <span className="cursor-pointer rounded border border-blue-200 bg-blue-100 px-1 py-[1px] text-gray-600 hover:bg-blue-200">
        all {parameter.name.toLowerCase()}
      </span>
    );
  }

  return (
    <div className="relative">
      <div className="flex cursor-pointer flex-wrap items-center rounded border border-blue-200 bg-blue-100 px-1 py-[1px] hover:bg-blue-200">
        {parameter.value.map((v, index) => (
          <span key={index} className="mb-1 mr-1">
            {v.label}
          </span>
        ))}
        <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
      </div>
    </div>
  );
};

const MultiSelectListCreatableParameter = ({
  parameterName,
  parameterValue,
  updatePlan,
}: {
  parameterName: string;
  parameterValue: ParameterValue;
  updatePlan: (parameterName: string, newValue: ParameterValue) => void;
}) => {
  const [newValue, setNewValue] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const addNewValue = (value: string) => {
    const trimmedValue = value.trim();
    if (!trimmedValue) {
      setNewValue('');
      setIsAdding(false);
      return;
    }
    updatePlan(parameterName, [...(parameterValue as Option[]), { label: trimmedValue, value: trimmedValue }]);
    setNewValue('');
    setIsAdding(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        addNewValue(newValue);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [newValue]);

  const handleAddNew = () => {
    setIsAdding(true);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addNewValue(newValue);
    } else if (e.key === 'Escape') {
      setNewValue('');
      setIsAdding(false);
    }
  };

  const handleRemoveParameter = (indexToRemove: number) => {
    updatePlan(
      parameterName,
      (parameterValue as Option[]).filter((_, index) => index !== indexToRemove),
    );
  };

  return (
    <span className="flex flex-wrap items-center">
      {(parameterValue as Option[]).map((v, index) => (
        <span
          key={index}
          className="group relative mb-1 mr-1 rounded border border-blue-200 bg-blue-100 px-1 py-[1px] text-gray-600"
        >
          {v.label}
          <button
            onClick={() => handleRemoveParameter(index)}
            className="absolute -right-1 -top-1 flex h-3 w-3 items-center justify-center rounded-full bg-gray-800 text-white opacity-0 transition-opacity group-hover:opacity-100"
          >
            <FontAwesomeIcon icon={faXmark} className="h-2 w-2" />
          </button>
        </span>
      ))}
      {isAdding ? (
        <input
          ref={inputRef}
          type="text"
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
          onKeyDown={handleInputKeyDown}
          className="mx-1 mb-1 w-16 rounded border border-blue-200 bg-blue-100 px-1 py-[1px] text-gray-600 outline-none"
          autoFocus
        />
      ) : (
        <button
          onClick={handleAddNew}
          className="mb-1 ml-1 flex h-3 w-3 items-center justify-center rounded-full bg-gray-700 text-sm text-white hover:bg-gray-900"
        >
          <FontAwesomeIcon icon={faPlus} className="h-2 w-2" />
        </button>
      )}
    </span>
  );
};

export {
  StringParameter,
  BooleanParameter,
  SingleSelectListParameter,
  MultiSelectListParameter,
  MultiSelectListCreatableParameter,
};
