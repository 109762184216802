import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';

import Actions from './components/Actions';
import GetStarted from './components/GetStarted';
import Guides from './components/Guides';
import RecentActivity from './components/RecentActivity';
import useGetUserActivity from './hooks/useGetUserActivity';

const ActivityPanel = () => {
  const { user } = useAuthInfo();
  const userId = user?.userId || '';
  const {
    data: userActivity,
    isLoading: isLoadingUserActivity,
    isFetching: isFetchingUserActivity,
  } = useGetUserActivity(userId);

  return (
    <div className="w-1/3 py-4">
      {isLoadingUserActivity || isFetchingUserActivity ? (
        <div className="flex h-full items-center justify-center">
          <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
        </div>
      ) : userActivity?.userActive ? (
        <>
          <Actions userId={userId} />
          <RecentActivity userId={userId} />
          <Guides userId={userId} />
        </>
      ) : (
        <GetStarted userId={userId} />
      )}
    </div>
  );
};

export default ActivityPanel;
